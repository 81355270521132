
import {Component, Vue} from 'vue-property-decorator';
import shopAccountBillList from './bill-list.vue';
import shopAccountShopList from './shop-list.vue';
import shopAccountDetail from './detail.vue';
import shopAccountUnusualDetail from './unusual-detail.vue';
@Component({
  name: 'shopAccount',
  components: {
    shopAccountBillList,
    shopAccountDetail,
    shopAccountShopList,
    shopAccountUnusualDetail,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'shop';
  }
}
