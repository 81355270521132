
import {Component, Vue} from 'vue-property-decorator';
import {shops, orders} from '../../../../resources';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {ObjectId} from 'bson';
import moment from 'moment';
import Pagination from '@/components/Pagination/index.vue';
import {Order} from '@/externals/MaxCI-Order-v1';
import lodash from 'lodash';
import {EJSON} from 'bson';

interface SummaryData {
  applicationDiscount: number;
  delivery: number;
  deliveryServiceAmount: number;
  packing: number;
  product: number;
  refund: number;
  shopAmount: number;
  shopDiscount: number;
  tecServiceAmount: number;
  user: number;
  _id: null;
}
@Component({
  name: 'billReconciliationDetail',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private shopSearchData = [] as Array<Shop>;
  private shopId = (this.$route.query.shopId as string) ?? '';
  private date = (this.$route.query.date as string) ?? '';
  private settlementType = this.$route.query.settlementType;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private summaryData = null as null | SummaryData;
  private list: Array<Order> = []; //已经结算列表
  private total = 0;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private listLoading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {}; //店铺信息
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';
  async created() {
    this.checkSummary();
    this.updateList();
    //店铺搜索查询所有店铺
    const shopSearchData = [] as Array<Shop>;
    const shopCount = await shops.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          if (this.role === '店铺' || this.role === '财务') {
            match(
              f => f('_id'),
              e => e.$in(this.shopAuthority),
            );
          }
          return match;
        })
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    const count = shopCount[0].count[0]
      ? shopCount[0].count[0].count.valueOf()
      : 0;
    let i = 0;
    while (i < Math.ceil(count / 100)) {
      i++;
      const list = await shops.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            if (this.role === '店铺' || this.role === '财务') {
              match(
                f => f('_id'),
                e => e.$in(this.shopAuthority),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort => sort(f => f('_id'), '降序'))
                .$skip((i - 1) * 100)
                .$limit(100),
            ),
          ),
      );
      shopSearchData.push(...list[0].table);
    }
    this.shopSearchData = shopSearchData;
  }
  //查看订单
  private checkOrder(id: ObjectId) {
    this.$router.push({
      path: '/application/' + this.$route.params.applicationId + '/order/index',
      query: {
        menu: '订单列表',
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //获取店铺信息
  private getShopInfo(id: ObjectId) {
    let text = '';
    if (this.shopInfoData[id.toHexString()]) {
      text = this.shopInfoData[id.toHexString()].spec.name;
    }
    return text;
  }
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
    this.checkSummary();
  }
  private async updateList() {
    try {
      this.listLoading = true;
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                );
                if (this.shopId) {
                  match(
                    f => f('spec')('shopId'),
                    e => e.$eq(ObjectId.createFromHexString(this.shopId)),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                return query;
              });
              if (this.date) {
                and(query =>
                  query(
                    f => f('spec')('serial')('date'),
                    e => e.$eq(moment(this.date).format('YYYY-MM-DD')),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      //查询店铺信息
      let shopIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        if (item.spec.type === '商城' && item.spec.shopId) {
          shopIdData.push(item.spec.shopId);
        }
      });
      //查询店铺信息
      shopIdData = Array.from(new Set(shopIdData));
      const shopData = await shops.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(shopIdData),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        ),
      );
      this.shopInfoData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        shopData.map(v => v._id.toHexString()) as any,
        shopData,
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
    } catch (e) {
      console.log(e);
    } finally {
      this.listLoading = false;
    }
  }
  private async checkSummary() {
    try {
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                );
                if (this.shopId) {
                  match(
                    f => f('spec')('shopId'),
                    e => e.$eq(ObjectId.createFromHexString(this.shopId)),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                return query;
              });
              if (this.date) {
                and(query =>
                  query(
                    f => f('spec')('serial')('date'),
                    e => e.$eq(moment(this.date).format('YYYY-MM-DD')),
                  ),
                );
              }
              return and;
            }),
          )
          .$group(
            e => e.$literal(null),
            group =>
              group('user', e =>
                e.$sum(sum =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  sum.$fieldPath(f => f('spec')('amount')('user')),
                ),
              )('product', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f => f('spec')('amount')('product')),
                ),
              )('packing', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f => f('spec')('amount')('packing')),
                ),
              )('delivery', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f => f('spec')('amount')('delivery')),
                ),
              )('shopDiscount', e =>
                e.$sum(sum =>
                  sum.$add(
                    e =>
                      e.$fieldPath(f =>
                        f('spec')('amount')('fullReduction')('shop'),
                      ),
                    e =>
                      e.$fieldPath(f => f('spec')('amount')('coupon')('shop')),
                  ),
                ),
              )('applicationDiscount', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f => f('spec')('amount')('application')),
                ),
              )('refund', e =>
                e.$sum(sum => sum.$fieldPath(f => f('spec')('refund')('user'))),
              )('tecServiceAmount', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f =>
                    f('status')('settlement')('input')('tecServiceAmount'),
                  ),
                ),
              )('deliveryServiceAmount', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f =>
                    f('status')('settlement')('input')('deliveryServiceAmount'),
                  ),
                ),
              )('shopAmount', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f =>
                    f('status')('settlement')('input')('shopAmount'),
                  ),
                ),
              ),
          ),
      );
      if (list.length > 0) {
        this.summaryData = list[0];
      }
    } catch (e) {
      console.log(e);
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
}
