
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  shops,
  shopSettlements,
  applications,
  orders,
  lushpayMerchants,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import moment from 'moment';
import xlsx from 'xlsx';
import {MallOrderSpec, MallOrderStatus} from '@/externals/MaxCI-Order-v1';
import lodash from 'lodash';
import {EJSON} from 'bson';

interface SettlementList {
  _id: {
    date: string;
    status?: string;
  };
  settlementAmount: number;
  applicationAmount: number;
  shopAmount: number;
  settlementTime?: Date;
}

@Component({
  name: 'billReconciliationList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private selectData = null as Date | null;
  private pickerOptions = {
    onPick: (time: {minDate: Date; maxDate: Date}) => {
      this.selectData = time.minDate;
      if (time.maxDate) {
        this.selectData = null;
      }
    },
    disabledDate: (time: Date) => {
      if (this.selectData) {
        const con1 =
          new Date(this.selectData).getTime() - 180 * 24 * 60 * 60 * 1000;
        const con2 =
          new Date(this.selectData).getTime() + 180 * 24 * 60 * 60 * 1000;
        return time.getTime() < con1 || time.getTime() > con2;
      }
    },
  };
  private loading = false;
  private shopSearchData = [] as Array<Shop>;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private searchData = {
    shopId: '',
    time: [
      moment({hour: 0}).subtract({day: 30}).format('YYYY-MM-DD'),
      moment({hour: 0}).format('YYYY-MM-DD'),
    ],
  };
  private listLoading = false;
  private list: Array<SettlementList> = []; //已经结算列表
  private listParams = {
    page: 1,
    limit: 10,
  };
  private listPage = {
    page: 1,
    limit: 10,
  };
  private total = 0;
  private settlementCycle = 1; //结算周期
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';

  async created() {
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }

    if (this.$route.query.shopId) {
      this.searchData.shopId = this.$route.query.shopId as string;
    }
    //查询结算周期
    const application =
      (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true) ?? null;
    if (application) {
      this.settlementCycle = application?.spec?.config?.settlementCycle ?? 1;
    }
    this.updateList();
    //店铺搜索查询所有店铺
    const shopSearchData = [] as Array<Shop>;
    const shopCount = await shops.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          if (this.role === '店铺' || this.role === '财务') {
            match(
              f => f('_id'),
              e => e.$in(this.shopAuthority),
            );
          }
          return match;
        })
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    const count = shopCount[0].count[0]
      ? shopCount[0].count[0].count.valueOf()
      : 0;
    let i = 0;
    while (i < Math.ceil(count / 100)) {
      i++;
      const list = await shops.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            if (this.role === '店铺' || this.role === '财务') {
              match(
                f => f('_id'),
                e => e.$in(this.shopAuthority),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort => sort(f => f('_id'), '降序'))
                .$skip((i - 1) * 100)
                .$limit(100),
            ),
          ),
      );
      shopSearchData.push(...list[0].table);
    }
    this.shopSearchData = shopSearchData;
  }
  get unsettlementList() {
    return this.list.filter(item => {
      return !item._id.status || item._id.status === '待结算';
    });
  }
  get settledList() {
    return this.list.filter(item => {
      return item._id.status && item._id.status !== '待结算';
    });
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.list = [];
    this.updateList();
  }
  private async updateList() {
    try {
      this.listLoading = true;
      //查询最新已结算数据
      const time = await shopSettlements.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.shopId) {
                  query(
                    f => f('spec')('shopId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.shopId),
                      ),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('入账'),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$group(e =>
            e.$object(object =>
              object('date', e => e.$fieldPath(f => f('spec')('date')))(
                'status',
                e => e.$fieldPath(f => f('status')('phase')),
              ),
            ),
          )
          .$sort(sort => sort(f => f('_id'), '降序'))
          .$limit(1),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let order = [] as any;
      let settledNum = 0,
        unsettledNum = 0;
      if (time.length > 0) {
        //查询最新已结算数据的日期是否等于筛选日期的最后一天
        const diffTime =
          this.searchData.time && this.searchData.time.length > 1
            ? this.searchData.time[1]
            : new Date();
        //不相等则存在未结算订单  在订单列表中查询未结算订单
        if (
          moment(time[0]._id.date, 'YYYY-MM-DD').isBefore(
            moment(diffTime, 'YYYY-MM-DD'),
            'day',
          )
        ) {
          //订单中未结算数据数量
          unsettledNum =
            (
              await orders.find(stage =>
                stage
                  .$match(match =>
                    match.$and(and => {
                      and(query => {
                        query(
                          f => f('status')('phase'),
                          e => e.$in(['支付', '接单', '打包', '配送', '完成']),
                        )(
                          f => f('spec')('type'),
                          e => e.$eq('商城'),
                        )(
                          f => f('spec')('applicationId'),
                          e => e.$eq(this.applicationId),
                        );
                        if (this.searchData.shopId) {
                          query(
                            f => f('spec')('shopId'),
                            e =>
                              e.$eq(
                                ObjectId.createFromHexString(
                                  this.searchData.shopId,
                                ),
                              ),
                          );
                        } else {
                          if (this.role === '店铺' || this.role === '财务') {
                            match(
                              f => f('spec')('shopId'),
                              e => e.$in(this.shopAuthority),
                            );
                          }
                        }
                        return query;
                      });
                      and(query =>
                        query(
                          f => f('metadata')('creationTimestamp'),
                          e =>
                            e.$gt(
                              moment(time[0]._id.date).endOf('day').toDate(),
                            ),
                        ),
                      );
                      and(query =>
                        query(
                          f => f('metadata')('creationTimestamp'),
                          e =>
                            e.$lte(
                              moment(this.searchData.time[1])
                                .endOf('day')
                                .toDate(),
                            ),
                        ),
                      );
                      return and;
                    }),
                  )
                  .$group(e =>
                    e.$object(object =>
                      object('date', e =>
                        e.$dateToString(
                          date =>
                            date.$fieldPath(f =>
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              f('metadata')('creationTimestamp'),
                            ),
                          format => format.$literal('%Y-%m-%d'),
                          timezone => timezone.$literal('+08'),
                        ),
                      ),
                    ),
                  )
                  .$count('count'),
              )
            ).find(() => true)?.count ?? 0;
          //订单中未结算数据列表
          if (
            (this.listParams.page - 1) * this.listParams.limit <
            unsettledNum
          ) {
            order = await orders.find(stage =>
              stage
                .$match(match =>
                  match.$and(and => {
                    and(query => {
                      query(
                        f => f('status')('phase'),
                        e => e.$in(['支付', '接单', '打包', '配送', '完成']),
                      )(
                        f => f('spec')('type'),
                        e => e.$eq('商城'),
                      )(
                        f => f('spec')('applicationId'),
                        e => e.$eq(this.applicationId),
                      );
                      if (this.searchData.shopId) {
                        query(
                          f => f('spec')('shopId'),
                          e =>
                            e.$eq(
                              ObjectId.createFromHexString(
                                this.searchData.shopId,
                              ),
                            ),
                        );
                      } else {
                        if (this.role === '店铺' || this.role === '财务') {
                          match(
                            f => f('spec')('shopId'),
                            e => e.$in(this.shopAuthority),
                          );
                        }
                      }
                      return query;
                    });
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e =>
                          e.$gt(moment(time[0]._id.date).endOf('day').toDate()),
                      ),
                    );
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e =>
                          e.$lte(
                            moment(this.searchData.time[1])
                              .endOf('day')
                              .toDate(),
                          ),
                      ),
                    );
                    return and;
                  }),
                )
                .$group(
                  e =>
                    e.$object(object =>
                      object('date', e =>
                        e.$dateToString(
                          date =>
                            date.$fieldPath(f =>
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              f('metadata')('creationTimestamp'),
                            ),
                          format => format.$literal('%Y-%m-%d'),
                          timezone => timezone.$literal('+08'),
                        ),
                      ),
                    ),
                  group =>
                    group('settlementAmount', e =>
                      e.$sum(sum =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sum.$fieldPath(f => f('spec')('amount')('user')),
                      ),
                    )('applicationAmount', e =>
                      e.$sum(sum =>
                        sum.$fieldPath(f =>
                          f('status')('settlement')('input')(
                            'applicationAmount',
                          ),
                        ),
                      ),
                    )('shopAmount', e =>
                      e.$sum(sum =>
                        sum.$fieldPath(f =>
                          f('status')('settlement')('input')('shopAmount'),
                        ),
                      ),
                    )('settlementTime', e =>
                      e.$max(max =>
                        max.$fieldPath(f => f('metadata')('updationTimestamp')),
                      ),
                    ),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit)
                .$sort(sort => sort(f => f('_id')('date'), '降序')),
            );
          }
        }
      }
      settledNum =
        (
          await shopSettlements.find(stage =>
            stage
              .$match(match =>
                match.$and(and => {
                  and(query => {
                    if (this.searchData.shopId) {
                      query(
                        f => f('spec')('shopId'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(
                              this.searchData.shopId,
                            ),
                          ),
                      );
                    } else {
                      if (this.role === '店铺' || this.role === '财务') {
                        match(
                          f => f('spec')('shopId'),
                          e => e.$in(this.shopAuthority),
                        );
                      }
                    }
                    query(
                      f => f('spec')('applicationId'),
                      e => e.$eq(this.applicationId),
                    )(
                      f => f('spec')('type'),
                      e => e.$eq('入账'),
                    );
                    return query;
                  });
                  if (this.searchData.time && this.searchData.time.length > 0) {
                    and(query =>
                      query(
                        f => f('spec')('date'),
                        e =>
                          e.$gte(
                            moment(this.searchData.time[0]).format(
                              'YYYY-MM-DD',
                            ),
                          ),
                      ),
                    );
                    and(query =>
                      query(
                        f => f('spec')('date'),
                        e =>
                          e.$lte(
                            moment(this.searchData.time[1]).format(
                              'YYYY-MM-DD',
                            ),
                          ),
                      ),
                    );
                  }
                  return and;
                }),
              )
              .$group(e =>
                e.$object(object =>
                  object('date', e => e.$fieldPath(f => f('spec')('date')))(
                    'status',
                    e => e.$fieldPath(f => f('status')('phase')),
                  ),
                ),
              )
              .$count('count'),
          )
        ).find(() => true)?.count ?? 0;
      this.total = settledNum + unsettledNum;
      //      1      2        3
      //6    0 4    4 10    14 10
      //12   0 0    0 8     8  10  18 10
      //28   0 0    0 0     0  2   2  10  12  10
      //0    0 10   10 10   20 10
      //10-(n-Math.floor(n/10)*10)
      //(page <= Math.ceil(n/10)?0:(page-Math.ceil(n/10))*10-(n-Math.floor(n/10)*10))
      //page <= Math.floor(n/10)?0:page <= (Math.floor(n/10)+1)?10-(n-Math.floor(n/10)*10):10

      const page =
        this.listParams.page <=
        (unsettledNum ? Math.ceil(unsettledNum / this.listParams.limit) : 1)
          ? 0
          : (this.listParams.page -
              (unsettledNum
                ? Math.ceil(unsettledNum / this.listParams.limit)
                : 1)) *
              this.listParams.limit -
            (unsettledNum -
              Math.floor(unsettledNum / this.listParams.limit) *
                this.listParams.limit);
      const limit =
        this.listParams.page <= Math.floor(unsettledNum / this.listParams.limit)
          ? 0
          : this.listParams.page <=
            Math.floor(unsettledNum / this.listParams.limit) + 1
          ? this.listParams.limit -
            (unsettledNum -
              Math.floor(unsettledNum / this.listParams.limit) *
                this.listParams.limit)
          : this.listParams.limit;
      const list = await shopSettlements.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.shopId) {
                  query(
                    f => f('spec')('shopId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.shopId),
                      ),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('入账'),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$group(
            e =>
              e.$object(object =>
                object('date', e => e.$fieldPath(f => f('spec')('date')))(
                  'status',
                  e => e.$fieldPath(f => f('status')('phase')),
                ),
              ),
            group =>
              group('settlementAmount', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f => f('spec')('settlementAmount')),
                ),
              )('applicationAmount', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f => f('spec')('applicationAmount')),
                ),
              )('shopAmount', e =>
                e.$sum(sum => sum.$fieldPath(f => f('spec')('shopAmount'))),
              )('settlementTime', e =>
                e.$max(max =>
                  max.$fieldPath(f => f('metadata')('updationTimestamp')),
                ),
              ),
          )
          .$sort(sort => sort(f => f('_id'), '降序'))
          .$skip(page)
          .$limit(limit),
      );
      if (order.length > 0) {
        this.list.push(...order, ...list);
      } else {
        this.list = list;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    } finally {
      this.listLoading = false;
    }
  }
  private async oldExportShop() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                );
                if (this.searchData.shopId) {
                  match(
                    f => f('spec')('shopId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.shopId),
                      ),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0]).startOf('day').toDate(),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1]).endOf('day').toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$group(e => e.$fieldPath(f => f('spec')('shopId')))
          .$count('count'),
      );
      if (count.length === 0 || !count[0].count) {
        this.$message.warning('暂无数据');
        return;
      }
      reportList.push([
        '店铺名称',
        '收款商户',
        '商品总价(￥)',
        '包装费(￥)',
        '配送费(￥)',
        '商家优惠(￥)',
        '应用优惠(￥)',
        '订单退款(￥)',
        '技术服务费(￥)',
        '配送服务费(￥)',
        '订单金额(￥)',
        '商家结算金额(￥)',
      ]);
      let i = 0;
      while (i < Math.ceil(count[0].count / 100)) {
        i++;
        const list = await orders.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  query(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  )(
                    f => f('spec')('type'),
                    e => e.$eq('商城'),
                  )(
                    f => f('status')('payInfo'),
                    e => e.$exists(true),
                  );
                  if (this.searchData.shopId) {
                    match(
                      f => f('spec')('shopId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.shopId),
                        ),
                    );
                  } else {
                    if (this.role === '店铺' || this.role === '财务') {
                      match(
                        f => f('spec')('shopId'),
                        e => e.$in(this.shopAuthority),
                      );
                    }
                  }
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gte(
                          moment(this.searchData.time[0])
                            .startOf('day')
                            .toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1]).endOf('day').toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$group(
              e => e.$fieldPath(f => f('spec')('shopId')),
              group =>
                group('product', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('amount')('product')),
                  ),
                )('packing', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('amount')('packing')),
                  ),
                )('delivery', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('amount')('delivery')),
                  ),
                )('shopFullReduction', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('spec')('amount')('fullReduction')('shop'),
                    ),
                  ),
                )('shopCoupon', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('amount')('coupon')('shop')),
                  ),
                )('application', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('amount')('application')),
                  ),
                )('refund', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('refund')('user')),
                  ),
                )('tecServiceAmount', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('status')('settlement')('input')('tecServiceAmount'),
                    ),
                  ),
                )('deliveryServiceAmount', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('status')('settlement')('input')(
                        'deliveryServiceAmount',
                      ),
                    ),
                  ),
                )('user', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('amount')('user')),
                  ),
                )('shopAmount', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('status')('settlement')('input')('shopAmount'),
                    ),
                  ),
                ),
            )
            .$skip((i - 1) * 100)
            .$limit(100),
        );

        //获取店铺信息 买家信息
        let shopIdData: Array<ObjectId> = [];
        list.forEach(item => {
          shopIdData.push(item._id);
        });
        //获取店铺信息
        shopIdData = Array.from(new Set(shopIdData));
        const shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        const shopInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shopData.map(v => v._id.toHexString()) as any,
          shopData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
        /**支付宝户**/
        let merchantIds = [] as Array<string>;
        for (let s in shopInfoData) {
          merchantIds.push(shopInfoData[s].spec.merchantId);
        }
        merchantIds = Array.from(new Set(merchantIds));
        const lushpayMerchantData = await lushpayMerchants.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('merchantId'),
              e => e.$in(merchantIds),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        const merchantInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          lushpayMerchantData.map(v => v.spec.merchantId) as any,
          lushpayMerchantData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
        for (let item of list) {
          reportList.push([
            shopInfoData[item._id.toHexString()]
              ? shopInfoData[item._id.toHexString()].spec.name
              : '',
            shopInfoData[item._id.toHexString()]
              ? merchantInfoData[
                  shopInfoData[item._id.toHexString()].spec.merchantId
                ]
                ? merchantInfoData[
                    shopInfoData[item._id.toHexString()].spec.merchantId
                  ].spec.name
                : ''
              : '',
            Number((item.product / 100).toFixed(2)),
            Number((item.packing / 100).toFixed(2)),
            Number((item.delivery / 100).toFixed(2)),
            Number(
              ((item.shopFullReduction + item.shopCoupon) / 100).toFixed(2),
            ),
            Number((item.application / 100).toFixed(2)),
            Number((item.refund / 100).toFixed(2)),
            Number((item.tecServiceAmount / 100).toFixed(2)),
            Number((item.deliveryServiceAmount / 100).toFixed(2)),
            Number((item.user / 100).toFixed(2)),
            Number((item.shopAmount / 100).toFixed(2)),
          ]);
        }
      }
      const time =
        this.searchData.time && this.searchData.time.length > 0
          ? `${moment(this.searchData.time[0]).format('YYYY-MM-DD')}至${moment(
              this.searchData.time[1],
            ).format('YYYY-MM-DD')}`
          : '';
      const filename = `${time}店铺汇总.xlsx`; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }

  //导出店铺汇总
  private async exportShop() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = await shopSettlements.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('入账'),
                );
                if (this.searchData.shopId) {
                  match(
                    f => f('spec')('shopId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.shopId),
                      ),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$group(e => e.$fieldPath(f => f('spec')('shopId')))
          .$count('count'),
      );
      // const count1 = await orders.find(stage =>
      //   stage
      //     .$match(match =>
      //       match.$and(and => {
      //         and(query => {
      //           query(
      //             f => f('spec')('applicationId'),
      //             e => e.$eq(this.applicationId),
      //           )(
      //             f => f('spec')('type'),
      //             e => e.$eq('商城'),
      //           )(
      //             f => f('status')('payInfo'),
      //             e => e.$exists(true),
      //           );
      //           if (this.searchData.shopId) {
      //             match(
      //               f => f('spec')('shopId'),
      //               e =>
      //                 e.$eq(
      //                   ObjectId.createFromHexString(this.searchData.shopId),
      //                 ),
      //             );
      //           } else {
      //             if (this.role === '店铺' || this.role === '财务') {
      //               match(
      //                 f => f('spec')('shopId'),
      //                 e => e.$in(this.shopAuthority),
      //               );
      //             }
      //           }
      //           return query;
      //         });
      //         if (this.searchData.time && this.searchData.time.length > 0) {
      //           and(query =>
      //             query(
      //               f => f('metadata')('creationTimestamp'),
      //               e =>
      //                 e.$gte(
      //                   moment(this.searchData.time[0]).startOf('day').toDate(),
      //                 ),
      //             ),
      //           );
      //           and(query =>
      //             query(
      //               f => f('metadata')('creationTimestamp'),
      //               e =>
      //                 e.$lte(
      //                   moment(this.searchData.time[1]).endOf('day').toDate(),
      //                 ),
      //             ),
      //           );
      //         }
      //         return and;
      //       }),
      //     )
      //     .$group(e => e.$fieldPath(f => f('spec')('shopId')))
      //     .$count('count'),
      // );
      if (count.length === 0 || !count[0].count) {
        this.$message.warning('暂无数据');
        return;
      }
      reportList.push([
        '店铺名称',
        '收款商户',
        '商品总价(￥)',
        '包装费(￥)',
        '配送费(￥)',
        '商家优惠(￥)',
        '应用优惠(￥)',
        '订单退款(￥)',
        '技术服务费(￥)',
        '配送服务费(￥)',
        '订单金额(￥)',
        '商家结算金额(￥)',
      ]);
      let i = 0;
      while (i < Math.ceil(count[0].count / 100)) {
        i++;
        const list = await shopSettlements.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  query(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  )(
                    f => f('spec')('type'),
                    e => e.$eq('入账'),
                  );
                  if (this.searchData.shopId) {
                    match(
                      f => f('spec')('shopId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.shopId),
                        ),
                    );
                  } else {
                    if (this.role === '店铺' || this.role === '财务') {
                      match(
                        f => f('spec')('shopId'),
                        e => e.$in(this.shopAuthority),
                      );
                    }
                  }
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('spec')('date'),
                      e =>
                        e.$gte(
                          moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('spec')('date'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1]).format('YYYY-MM-DD'),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$group(
              e => e.$fieldPath(f => f('spec')('shopId')),
              group =>
                group('product', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('spec')('totalOrderAmount')('product'),
                    ),
                  ),
                )('packing', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('spec')('totalOrderAmount')('packing'),
                    ),
                  ),
                )('delivery', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('spec')('totalOrderAmount')('delivery'),
                    ),
                  ),
                )('shopFullReduction', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('spec')('totalOrderAmount')('fullReduction')('shop'),
                    ),
                  ),
                )('shopCoupon', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('spec')('totalOrderAmount')('coupon')('shop'),
                    ),
                  ),
                )('application', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('spec')('totalOrderAmount')('application'),
                    ),
                  ),
                )('refund', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('totalRefundAmount')('user')),
                  ),
                )('tecServiceAmount', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('tecServiceAmount')),
                  ),
                )('deliveryServiceAmount', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('deliveryServiceAmount')),
                  ),
                )('user', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('totalOrderAmount')('user')),
                  ),
                )('shopAmount', e =>
                  e.$sum(sum => sum.$fieldPath(f => f('spec')('shopAmount'))),
                ),
            )
            .$skip((i - 1) * 100)
            .$limit(100),
        );

        //获取店铺信息 买家信息
        let shopIdData: Array<ObjectId> = [];
        list.forEach(item => {
          shopIdData.push(item._id);
        });
        //获取店铺信息
        shopIdData = Array.from(new Set(shopIdData));
        const shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        const shopInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shopData.map(v => v._id.toHexString()) as any,
          shopData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
        /**支付宝户**/
        let merchantIds = [] as Array<string>;
        for (let s in shopInfoData) {
          merchantIds.push(shopInfoData[s].spec.merchantId);
        }
        merchantIds = Array.from(new Set(merchantIds));
        const lushpayMerchantData = await lushpayMerchants.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('merchantId'),
              e => e.$in(merchantIds),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        const merchantInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          lushpayMerchantData.map(v => v.spec.merchantId) as any,
          lushpayMerchantData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
        for (let item of list) {
          reportList.push([
            shopInfoData[item._id.toHexString()]
              ? shopInfoData[item._id.toHexString()].spec.name
              : '',
            shopInfoData[item._id.toHexString()]
              ? merchantInfoData[
                  shopInfoData[item._id.toHexString()].spec.merchantId
                ]
                ? merchantInfoData[
                    shopInfoData[item._id.toHexString()].spec.merchantId
                  ].spec.name
                : ''
              : '',
            Number((item.product / 100).toFixed(2)),
            Number((item.packing / 100).toFixed(2)),
            Number((item.delivery / 100).toFixed(2)),
            Number(
              ((item.shopFullReduction + item.shopCoupon) / 100).toFixed(2),
            ),
            Number((item.application / 100).toFixed(2)),
            Number((item.refund / 100).toFixed(2)),
            Number((item.tecServiceAmount / 100).toFixed(2)),
            Number((item.deliveryServiceAmount / 100).toFixed(2)),
            Number((item.user / 100).toFixed(2)),
            Number((item.shopAmount / 100).toFixed(2)),
          ]);
        }
      }
      const time =
        this.searchData.time && this.searchData.time.length > 0
          ? `${moment(this.searchData.time[0]).format('YYYY-MM-DD')}至${moment(
              this.searchData.time[1],
            ).format('YYYY-MM-DD')}`
          : '';
      const filename = `${time}店铺汇总.xlsx`; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //导出明细
  private async exportDetails(date: string) {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                );
                if (this.searchData.shopId) {
                  match(
                    f => f('spec')('shopId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.shopId),
                      ),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                return query;
              });
              if (date) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(date).startOf('day').toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$lte(moment(date).endOf('day').toDate()),
                  ),
                );
              }
              return and;
            }),
          )
          .$count('count'),
      );
      if (count.length === 0 || !count[0].count) {
        this.$message.warning('暂无数据');
        return;
      }
      reportList.push([
        '订单编号',
        '店铺名称',
        '商品总价(￥)',
        '包装费(￥)',
        '配送费(￥)',
        '商家优惠(￥)',
        '应用优惠(￥)',
        '订单退款(￥)',
        '技术服务费率',
        '保底技术服务费(￥)',
        '技术服务费(￥)',
        '配送服务费率',
        '保底配送服务费(￥)',
        '配送服务费(￥)',
        '商家结算金额(￥)',
      ]);
      let i = 0;
      while (i < Math.ceil(count[0].count / 100)) {
        i++;
        const list = await orders.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  query(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  )(
                    f => f('spec')('type'),
                    e => e.$eq('商城'),
                  )(
                    f => f('status')('payInfo'),
                    e => e.$exists(true),
                  );
                  if (this.searchData.shopId) {
                    match(
                      f => f('spec')('shopId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.shopId),
                        ),
                    );
                  } else {
                    if (this.role === '店铺' || this.role === '财务') {
                      match(
                        f => f('spec')('shopId'),
                        e => e.$in(this.shopAuthority),
                      );
                    }
                  }
                  return query;
                });
                if (date) {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$gte(moment(date).startOf('day').toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$lte(moment(date).endOf('day').toDate()),
                    ),
                  );
                }
                return and;
              }),
            )
            .$sort(sort =>
              sort(f => f('metadata')('creationTimestamp'), '降序'),
            )
            .$skip((i - 1) * 100)
            .$limit(100),
        );

        //获取店铺信息 买家信息
        let shopIdData: Array<ObjectId> = [];
        list.forEach(item => {
          if (item.spec.type === '商城') {
            shopIdData.push(item.spec.shopId);
          }
        });
        //获取店铺信息
        shopIdData = Array.from(new Set(shopIdData));
        const shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        const shopInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shopData.map(v => v._id.toHexString()) as any,
          shopData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
        for (let item of list) {
          const spec = item.spec as MallOrderSpec;
          const status = item.status as MallOrderStatus;
          reportList.push([
            item._id.toHexString(),
            shopInfoData[spec.shopId.toHexString()]
              ? shopInfoData[spec.shopId.toHexString()].spec.name
              : '',
            Number(((spec.amount?.product ?? 0) / 100).toFixed(2)),
            Number(((spec.amount?.packing ?? 0) / 100).toFixed(2)),
            Number(((spec.amount?.delivery ?? 0) / 100).toFixed(2)),
            Number(
              (
                ((spec.amount?.fullReduction.shop ?? 0) +
                  (spec.amount?.coupon.shop ?? 0)) /
                100
              ).toFixed(2),
            ),
            ((spec.amount?.application ?? 0) / 100).toFixed(2),
            spec.refund && spec.refund.user
              ? Number((spec.refund.user / 100).toFixed(2))
              : 0,
            status && status.settlement && status.settlement.input
              ? `${Math.round(
                  (status.settlement.input.tecServiceRate.rate / 10000) * 100,
                )}%`
              : 0,
            status && status.settlement && status.settlement.input
              ? Number(
                  (
                    status.settlement.input.tecServiceRate.minimum / 100
                  ).toFixed(2),
                )
              : 0,
            status && status.settlement && status.settlement.input
              ? Number(
                  (status.settlement.input.tecServiceAmount / 100).toFixed(2),
                )
              : 0,
            status && status.settlement && status.settlement.input
              ? `${Math.round(
                  (status.settlement.input.deliveryServiceRate.rate / 10000) *
                    100,
                )}%`
              : 0,
            status && status.settlement && status.settlement.input
              ? Number(
                  (
                    status.settlement.input.deliveryServiceRate.minimum / 100
                  ).toFixed(2),
                )
              : 0,
            status && status.settlement && status.settlement.input
              ? Number(
                  (status.settlement.input.deliveryServiceAmount / 100).toFixed(
                    2,
                  ),
                )
              : 0,
            status && status.settlement && status.settlement.input
              ? Number((status.settlement.input.shopAmount / 100).toFixed(2))
              : 0,
          ]);
        }
      }
      const filename = date + '对账单明细.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //导出汇总
  private async exportSummary() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      reportList.push([
        '账单日期',
        '账单金额(￥)',
        '商家结算金额(￥)',
        '应用结算金额(￥)',
        '结算日期',
        '结算状态',
      ]);
      const time = await shopSettlements.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.shopId) {
                  query(
                    f => f('spec')('shopId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.shopId),
                      ),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('入账'),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$group(e =>
            e.$object(object =>
              object('date', e => e.$fieldPath(f => f('spec')('date')))(
                'status',
                e => e.$fieldPath(f => f('status')('phase')),
              ),
            ),
          )
          .$sort(sort => sort(f => f('_id'), '降序'))
          .$limit(1),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let order = [] as any;
      if (time.length > 0) {
        const diffTime =
          this.searchData.time && this.searchData.time.length > 1
            ? this.searchData.time[1]
            : new Date();
        if (
          moment(time[0]._id.date, 'YYYY-MM-DD').isBefore(
            moment(diffTime, 'YYYY-MM-DD'),
            'day',
          )
        ) {
          order = await orders.find(stage =>
            stage
              .$match(match =>
                match.$and(and => {
                  and(query => {
                    query(
                      f => f('status')('phase'),
                      e => e.$in(['支付', '接单', '打包', '配送', '完成']),
                    )(
                      f => f('spec')('type'),
                      e => e.$eq('商城'),
                    )(
                      f => f('spec')('applicationId'),
                      e => e.$eq(this.applicationId),
                    );
                    if (this.searchData.shopId) {
                      query(
                        f => f('spec')('shopId'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(
                              this.searchData.shopId,
                            ),
                          ),
                      );
                    } else {
                      if (this.role === '店铺' || this.role === '财务') {
                        match(
                          f => f('spec')('shopId'),
                          e => e.$in(this.shopAuthority),
                        );
                      }
                    }
                    return query;
                  });
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gt(moment(time[0]._id.date).endOf('day').toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1]).endOf('day').toDate(),
                        ),
                    ),
                  );
                  return and;
                }),
              )
              .$group(
                e =>
                  e.$object(object =>
                    object('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    ),
                  ),
                group =>
                  group('settlementAmount', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('applicationAmount', e =>
                    e.$sum(sum =>
                      sum.$fieldPath(f =>
                        f('status')('settlement')('input')('applicationAmount'),
                      ),
                    ),
                  )('shopAmount', e =>
                    e.$sum(sum =>
                      sum.$fieldPath(f =>
                        f('status')('settlement')('input')('shopAmount'),
                      ),
                    ),
                  )('settlementTime', e =>
                    e.$max(max =>
                      max.$fieldPath(f => f('metadata')('updationTimestamp')),
                    ),
                  ),
              )
              .$sort(sort => sort(f => f('_id')('date'), '降序')),
          );
        }
      }
      if (order.length > 0) {
        for (let item of order) {
          reportList.push([
            item._id.date,
            Number((item.settlementAmount / 100).toFixed(2)),
            Number((item.shopAmount / 100).toFixed(2)),
            Number((item.applicationAmount / 100).toFixed(2)),
            `预计${this.getSettlementDate(item._id.date)}结算`,
            '未结算',
          ]);
        }
      }
      const count = await shopSettlements.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.shopId) {
                  query(
                    f => f('spec')('shopId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.shopId),
                      ),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('入账'),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$group(
            e =>
              e.$object(object =>
                object('date', e => e.$fieldPath(f => f('spec')('date')))(
                  'status',
                  e => e.$fieldPath(f => f('status')('phase')),
                ),
              ),
            group =>
              group('settlementAmount', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f => f('spec')('settlementAmount')),
                ),
              )('applicationAmount', e =>
                e.$sum(sum =>
                  sum.$fieldPath(f => f('spec')('applicationAmount')),
                ),
              )('shopAmount', e =>
                e.$sum(sum => sum.$fieldPath(f => f('spec')('shopAmount'))),
              )('settlementTime', e =>
                e.$max(max =>
                  max.$fieldPath(f => f('metadata')('updationTimestamp')),
                ),
              ),
          )
          .$count('count'),
      );
      if (count.length > 0 && count[0].count) {
        let i = 0;
        while (i < Math.ceil(count[0].count / 100)) {
          i++;
          const list = await shopSettlements.find(stage =>
            stage
              .$match(match =>
                match.$and(and => {
                  and(query => {
                    if (this.searchData.shopId) {
                      query(
                        f => f('spec')('shopId'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(
                              this.searchData.shopId,
                            ),
                          ),
                      );
                    } else {
                      if (this.role === '店铺' || this.role === '财务') {
                        match(
                          f => f('spec')('shopId'),
                          e => e.$in(this.shopAuthority),
                        );
                      }
                    }
                    query(
                      f => f('spec')('applicationId'),
                      e => e.$eq(this.applicationId),
                    )(
                      f => f('spec')('type'),
                      e => e.$eq('入账'),
                    );
                    return query;
                  });
                  if (this.searchData.time && this.searchData.time.length > 0) {
                    and(query =>
                      query(
                        f => f('spec')('date'),
                        e =>
                          e.$gte(
                            moment(this.searchData.time[0]).format(
                              'YYYY-MM-DD',
                            ),
                          ),
                      ),
                    );
                    and(query =>
                      query(
                        f => f('spec')('date'),
                        e =>
                          e.$lte(
                            moment(this.searchData.time[1]).format(
                              'YYYY-MM-DD',
                            ),
                          ),
                      ),
                    );
                  }
                  return and;
                }),
              )
              .$group(
                e =>
                  e.$object(object =>
                    object('date', e => e.$fieldPath(f => f('spec')('date')))(
                      'status',
                      e => e.$fieldPath(f => f('status')('phase')),
                    ),
                  ),
                group =>
                  group('settlementAmount', e =>
                    e.$sum(sum =>
                      sum.$fieldPath(f => f('spec')('settlementAmount')),
                    ),
                  )('applicationAmount', e =>
                    e.$sum(sum =>
                      sum.$fieldPath(f => f('spec')('applicationAmount')),
                    ),
                  )('shopAmount', e =>
                    e.$sum(sum => sum.$fieldPath(f => f('spec')('shopAmount'))),
                  )('settlementTime', e =>
                    e.$max(max =>
                      max.$fieldPath(f => f('metadata')('updationTimestamp')),
                    ),
                  ),
              )
              .$sort(sort => sort(f => f('_id'), '降序'))
              .$skip((i - 1) * 100)
              .$limit(100),
          );
          for (let item of list) {
            reportList.push([
              item._id.date,
              Number((item.settlementAmount / 100).toFixed(2)),
              Number((item.shopAmount / 100).toFixed(2)),
              Number((item.applicationAmount / 100).toFixed(2)),
              item._id.status === '待结算'
                ? `预计${this.getSettlementDate(item._id.date)}结算`
                : this.getTime(item.settlementTime ?? ''),
              item._id.status === '待结算' ? '未结算' : '已结算',
            ]);
          }
        }
      }
      if (reportList.length === 1) {
        this.$message.warning('暂无数据');
        return;
      }
      const filename = moment().format('YYYY-MM-DD') + '对账单汇总.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  private toDetail(date: string, type: string) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        date: date,
        shopId: this.searchData.shopId,
        settlementType: type,
      },
    });
  }
  getSettlementDate(date: string) {
    return moment(date).add({day: this.settlementCycle}).format('YYYY-MM-DD');
  }
  getTime(time: string | Date) {
    if (time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    }
    return '';
  }
}
