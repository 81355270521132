
import {Component, Vue} from 'vue-property-decorator';
import {orders, shops, shopSettlements} from '../../../../resources';
import {Order} from '@/externals';
import lodash from 'lodash';
import {ObjectId} from 'bson';
import Pagination from '@/components/Pagination/index.vue';
import {Shop} from '@/externals/MaxCI-Shop-v1';

@Component({
  name: 'shopAccountUnusualDetail',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private shopInfo: null | Shop = null;
  private shopId = ObjectId.createFromHexString(this.$route.query.id as string);
  private price = 0;
  private settleAccountsList: Array<Order> = []; //结算列表
  private withdrawalList: Array<Order> = []; //提现列表
  private listLoading = false;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private total = 0;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );

  async created() {
    //查询列表
    this.updateList();
    //查询余额
    this.shopInfo =
      (
        await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('_id'),
              e => e.$eq(this.shopId),
            ),
          ),
        )
      ).find(() => true) ?? null;
  }
  private async updateList() {
    try {
      this.listLoading = true;
      const list = await shopSettlements.find(stage =>
        stage.$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('type'),
                e => e.$eq('出账'),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('status')('phase'),
                e => e.$eq('待结算'),
              )(
                f => f('spec')('shopId'),
                e => e.$eq(this.shopId),
              );
              return query;
            });
            return and;
          }),
        ),
      );
      const orderList = await orders.find(stage =>
        stage
          .$match(match =>
            match(
              f => f('status')('settlement')('output')('settlementId'),
              e => e.$in(list.map(v => v._id)),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(this.shopId),
            )(
              f => f('status')('phase'),
              e => e.$in(['支付', '接单', '打包', '配送', '完成']),
            ),
          )
          .$sort(sort => sort(f => f('spec')('serial')('number'), '升序'))
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('summary', summaryStage =>
              summaryStage.$group(
                e => e.$literal(null),
                group =>
                  group('summaryAmount', e =>
                    e.$sum(sum =>
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  ),
              ),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = orderList[0].count[0]
        ? orderList[0].count[0].count.valueOf()
        : 0;
      this.price =
        orderList[0].summary.length > 0
          ? orderList[0].summary[0].summaryAmount
          : 0;
      this.withdrawalList = orderList[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    } finally {
      this.listLoading = false;
    }
  }
  //查看订单
  private checkOrder(id: ObjectId) {
    this.$router.push({
      path: '/application/' + this.$route.params.applicationId + '/order/index',
      query: {
        menu: '订单列表',
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'shop',
      },
    });
  }
}
