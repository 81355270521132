
import {Component, Vue} from 'vue-property-decorator';
import applicationAccountList from './list.vue';
import applicationAccountDetail from './detail.vue';
@Component({
  name: 'applicationAccount',
  components: {
    applicationAccountList,
    applicationAccountDetail,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
