
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {orders, shops} from '../../../../resources';
import {ObjectId} from 'bson';
import moment from 'moment';
import xlsx from 'xlsx';
import * as echarts from 'echarts';
import {ProductSnapshot} from '@/externals/MaxCI-Order-v1';
import {Shop} from '@/externals/MaxCI-Shop-v1';

interface ProductList {
  _id: {productId: ObjectId};
  count: number;
  price: number;
  productInfo: ProductSnapshot;
}
@Component({
  name: 'commoditySales',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private shopId = ObjectId.createFromHexString(
    this.$route.query?.shopId as string,
  );
  private startTime = (this.$route.query?.startTime ?? '') as string;
  private endTime = (this.$route.query?.endTime ?? '') as string;
  private shopInfo = null as null | Shop;
  private listLoading = false;
  private list = [] as Array<ProductList>;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private total = 0;
  private sortVal = '';
  private sortType = '';
  private xData: Array<string> = [];
  private yUser: Array<number> = [];
  private yCount: Array<number> = [];

  async created() {
    //查询店铺信息
    this.shopInfo =
      (
        await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.shopId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        )
      ).find(() => true) ?? null;
    this.checkList();
    this.checkChartsCount();
    this.checkSalesRate();
  }
  mounted() {
    const sales = document.getElementById('sales') as HTMLCanvasElement;
    const salesStatistics = document.getElementById(
      'salesStatistics',
    ) as HTMLCanvasElement;
    window.onresize = () => {
      return (() => {
        echarts.init(sales).resize();
        echarts.init(salesStatistics).resize();
      })();
    };
  }
  //查询销量占比
  private async checkSalesRate() {
    try {
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                )(
                  f => f('spec')('shopId'),
                  e => e.$eq(this.shopId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                );
                return query;
              });
              if (this.startTime && this.endTime) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.startTime).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.endTime)
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$unwind(f => f('spec')('items'), undefined, false)
          .$facet(facet =>
            facet('table', tableStage => {
              const tmallStage = tableStage.$group(
                e =>
                  e.$object(object =>
                    object('productId', e =>
                      e.$fieldPath(f => f('spec')('items')('productId')),
                    ),
                  ),
                group =>
                  group('count', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('items')('count')),
                    ),
                  )('productInfo', e =>
                    e.$first(first =>
                      first.$fieldPath(f => f('spec')('items')('snapshot')),
                    ),
                  ),
              );
              return tmallStage;
            }),
          ),
      );
      let total = 0;
      for (let item of list[0].table) {
        total = total + item.count;
      }
      const data = [] as Array<{value: number; name: string}>;
      let otherNum = 0;
      for (let item of list[0].table) {
        if ((item.count / total) * 100 > 1) {
          data.push({
            value: (item.count / total) * 100,
            name: item.productInfo.name,
          });
        } else {
          otherNum = otherNum + (item.count / total) * 100;
        }
      }
      if (otherNum > 0) {
        data.push({
          value: otherNum,
          name: '其他',
        });
      }

      console.log(data);
      const chart = document.getElementById('sales') as HTMLCanvasElement;
      const myChart = echarts.init(chart);
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {d}%',
        },
        series: [
          {
            name: '销量占比',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 2,
              borderColor: '#fff',
              borderWidth: 2,
            },
            data: data,
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
  }
  //查询销售统计
  private async checkChartsCount() {
    try {
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                )(
                  f => f('spec')('shopId'),
                  e => e.$eq(this.shopId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                );
                return query;
              });
              if (this.startTime && this.endTime) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.startTime).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.endTime)
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$unwind(f => f('spec')('items'), undefined, false)
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$group(
                  e =>
                    e.$object(object =>
                      object('date', e =>
                        e.$dateToString(
                          date =>
                            date.$fieldPath(f =>
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              f('metadata')('creationTimestamp'),
                            ),
                          format => format.$literal('%Y-%m-%d'),
                          timezone => timezone.$literal('+08'),
                        ),
                      ),
                    ),
                  group =>
                    group('price', e =>
                      e.$sum(sum =>
                        sum.$subtract(
                          subtract =>
                            subtract.$multiply(
                              multiply =>
                                multiply.$fieldPath(f =>
                                  f('spec')('items')('count'),
                                ),
                              multiply =>
                                multiply.$fieldPath(f =>
                                  f('spec')('items')('sku')('price'),
                                ),
                            ),
                          subtract =>
                            subtract.$add(
                              sadd =>
                                sadd.$multiply(
                                  multiply =>
                                    multiply.$fieldPath(f =>
                                      f('spec')('items')('count'),
                                    ),
                                  multiply =>
                                    multiply.$add(
                                      add =>
                                        add.$fieldPath(f =>
                                          f('spec')('items')('shopDiscount')(
                                            'fullReduction',
                                          ),
                                        ),
                                      add =>
                                        add.$fieldPath(f =>
                                          f('spec')('items')('shopDiscount')(
                                            'coupon',
                                          ),
                                        ),
                                    ),
                                ),
                              sadd =>
                                sadd.$multiply(
                                  multiply =>
                                    multiply.$fieldPath(f =>
                                      f('spec')('items')('count'),
                                    ),
                                  multiply =>
                                    multiply.$add(
                                      add =>
                                        add.$fieldPath(f =>
                                          f('spec')('items')(
                                            'applicationDiscount',
                                          )('fullReduction'),
                                        ),
                                      add =>
                                        add.$fieldPath(f =>
                                          f('spec')('items')(
                                            'applicationDiscount',
                                          )('coupon'),
                                        ),
                                    ),
                                ),
                            ),
                        ),
                      ),
                    )('count', e =>
                      e.$sum(sum =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sum.$fieldPath(f => f('spec')('items')('count')),
                      ),
                    ),
                )
                .$sort(sort => sort(f => f('_id')('date'), '升序')),
            ),
          ),
      );
      this.xData = list[0].table.map(v => v._id.date as string);
      this.yUser = list[0].table.map(v => v.price / 100);
      this.yCount = list[0].table.map(v => v.count);
      const chart = document.getElementById(
        'salesStatistics',
      ) as HTMLCanvasElement;
      const myChart = echarts.init(chart);
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: this.xData,
          max: this.xData[this.xData.length - 1],
          axisLabel: {
            showMaxLabel: true,
          },
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
        },
        yAxis: [
          {
            type: 'value',
            name: '销售金额',
            axisLine: {
              show: false, //隐藏坐标轴
            },
            axisTick: {
              show: false, //隐藏刻度线
            },
            axisLabel: {
              show: false, //隐藏值
            },
            position: 'left',
            splitLine: {show: false},
          },
          {
            type: 'value',
            name: '销量',
            axisLine: {
              show: false, //隐藏坐标轴
            },
            axisTick: {
              show: false, //隐藏刻度线
            },
            axisLabel: {
              show: false, //隐藏值
            },
            position: 'right',
            splitLine: {show: false},
          },
        ],
        legend: {
          orient: 'horizontal',
          padding: [0, 0, 10, 0],
          data: ['销量', '销售金额'],
        },
        series: [
          {
            name: '销售金额',
            data: this.yUser,
            type: 'bar',
            smooth: true,
            symbolSize: 6,
            yAxisIndex: 0,
            itemStyle: {
              color: '#657AFF',
            },
          },
          {
            name: '销量',
            data: this.yCount,
            type: 'line',
            smooth: false,
            symbol: 'circle',
            symbolSize: 5, //圆点大小
            yAxisIndex: 1,
            itemStyle: {
              color: '#6DD181',
            },
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
  }
  //排序
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sortChange(column: any) {
    this.sortVal = column.column.label;
    this.sortType = column.order === 'ascending' ? '升序' : '降序';
    this.checkList();
  }
  //查询商品销售明细
  private async checkList() {
    try {
      this.listLoading = true;
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                )(
                  f => f('spec')('shopId'),
                  e => e.$eq(this.shopId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                );
                return query;
              });
              if (this.startTime && this.endTime) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.startTime).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.endTime)
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$unwind(f => f('spec')('items'), undefined, false)
          .$facet(facet =>
            facet('table', tableStage => {
              const tmallStage = tableStage.$group(
                e =>
                  e.$object(object =>
                    object('productId', e =>
                      e.$fieldPath(f => f('spec')('items')('productId')),
                    ),
                  ),
                group =>
                  group('price', e =>
                    e.$sum(sum =>
                      sum.$subtract(
                        subtract =>
                          subtract.$multiply(
                            multiply =>
                              multiply.$fieldPath(f =>
                                f('spec')('items')('count'),
                              ),
                            multiply =>
                              multiply.$fieldPath(f =>
                                f('spec')('items')('sku')('price'),
                              ),
                          ),
                        subtract =>
                          subtract.$add(
                            sadd =>
                              sadd.$multiply(
                                multiply =>
                                  multiply.$fieldPath(f =>
                                    f('spec')('items')('count'),
                                  ),
                                multiply =>
                                  multiply.$add(
                                    add =>
                                      add.$fieldPath(f =>
                                        f('spec')('items')('shopDiscount')(
                                          'fullReduction',
                                        ),
                                      ),
                                    add =>
                                      add.$fieldPath(f =>
                                        f('spec')('items')('shopDiscount')(
                                          'coupon',
                                        ),
                                      ),
                                  ),
                              ),
                            sadd =>
                              sadd.$multiply(
                                multiply =>
                                  multiply.$fieldPath(f =>
                                    f('spec')('items')('count'),
                                  ),
                                multiply =>
                                  multiply.$add(
                                    add =>
                                      add.$fieldPath(f =>
                                        f('spec')('items')(
                                          'applicationDiscount',
                                        )('fullReduction'),
                                      ),
                                    add =>
                                      add.$fieldPath(f =>
                                        f('spec')('items')(
                                          'applicationDiscount',
                                        )('coupon'),
                                      ),
                                  ),
                              ),
                          ),
                      ),
                    ),
                  )('count', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('items')('count')),
                    ),
                  )('productInfo', e =>
                    e.$first(first =>
                      first.$fieldPath(f => f('spec')('items')('snapshot')),
                    ),
                  ),
              );
              if (this.sortVal === '销量') {
                tmallStage.$sort(sort =>
                  sort(f => f('count'), this.sortType as '升序' | '降序'),
                );
              }
              if (this.sortVal === '销售金额(￥)') {
                tmallStage.$sort(sort =>
                  sort(f => f('price'), this.sortType as '升序' | '降序'),
                );
              }
              tmallStage.$skip(
                (this.listParams.page - 1) * this.listParams.limit,
              );
              tmallStage.$limit(this.listParams.limit);
              return tmallStage;
            })('count', countStage =>
              countStage
                .$group(e =>
                  e.$object(object =>
                    object('productId', e =>
                      e.$fieldPath(f => f('spec')('items')('productId')),
                    ),
                  ),
                )
                .$count('count'),
            ),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
    } catch (e) {
      console.log(e);
    } finally {
      this.listLoading = false;
    }
  }
  //汇总导出
  private async chartExportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      reportList.push(['商品名称', '所属分类', '销量', '销售金额(￥)']);

      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                )(
                  f => f('spec')('shopId'),
                  e => e.$eq(this.shopId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                );
                return query;
              });
              if (this.startTime && this.endTime) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.startTime).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.endTime)
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$unwind(f => f('spec')('items'), undefined, false)
          .$facet(facet =>
            facet('table', tableStage => {
              const tmallStage = tableStage.$group(
                e =>
                  e.$object(object =>
                    object('productId', e =>
                      e.$fieldPath(f => f('spec')('items')('productId')),
                    ),
                  ),
                group =>
                  group('price', e =>
                    e.$sum(sum =>
                      sum.$subtract(
                        subtract =>
                          subtract.$multiply(
                            multiply =>
                              multiply.$fieldPath(f =>
                                f('spec')('items')('count'),
                              ),
                            multiply =>
                              multiply.$fieldPath(f =>
                                f('spec')('items')('sku')('price'),
                              ),
                          ),
                        subtract =>
                          subtract.$add(
                            sadd =>
                              sadd.$multiply(
                                multiply =>
                                  multiply.$fieldPath(f =>
                                    f('spec')('items')('count'),
                                  ),
                                multiply =>
                                  multiply.$add(
                                    add =>
                                      add.$fieldPath(f =>
                                        f('spec')('items')('shopDiscount')(
                                          'fullReduction',
                                        ),
                                      ),
                                    add =>
                                      add.$fieldPath(f =>
                                        f('spec')('items')('shopDiscount')(
                                          'coupon',
                                        ),
                                      ),
                                  ),
                              ),
                            sadd =>
                              sadd.$multiply(
                                multiply =>
                                  multiply.$fieldPath(f =>
                                    f('spec')('items')('count'),
                                  ),
                                multiply =>
                                  multiply.$add(
                                    add =>
                                      add.$fieldPath(f =>
                                        f('spec')('items')(
                                          'applicationDiscount',
                                        )('fullReduction'),
                                      ),
                                    add =>
                                      add.$fieldPath(f =>
                                        f('spec')('items')(
                                          'applicationDiscount',
                                        )('coupon'),
                                      ),
                                  ),
                              ),
                          ),
                      ),
                    ),
                  )('count', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('items')('count')),
                    ),
                  )('productInfo', e =>
                    e.$first(first =>
                      first.$fieldPath(f => f('spec')('items')('snapshot')),
                    ),
                  ),
              );
              if (this.sortVal === '销量') {
                tmallStage.$sort(sort =>
                  sort(f => f('count'), this.sortType as '升序' | '降序'),
                );
              }
              if (this.sortVal === '销售金额(￥)') {
                tmallStage.$sort(sort =>
                  sort(f => f('price'), this.sortType as '升序' | '降序'),
                );
              }
              return tmallStage;
            }),
          ),
      );
      list[0].table.forEach(item => {
        reportList.push([
          item.productInfo.name,
          this.getSort(item.productInfo.labels),
          item.count,
          Number((item.price / 100).toFixed(2)),
        ]);
      });
      const filename = moment().format('YYYY-MM-DD') + '商品销售明细.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //获取分类
  private getSort(labels: Array<string>) {
    let label = '';
    for (let item of labels) {
      if (item.indexOf('分类:') > -1) {
        label = item.split(':')[1];
      }
    }
    return label;
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
}
