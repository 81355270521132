
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import orderStatistics from './components/order-statistics/index.vue';
import deliveryOrderStatistics from './components/delivery-order-statistics/index.vue';
import billReconciliation from './components/bill-reconciliation/index.vue';
import applicationAccount from './components/application-account/index.vue';
import shopAccount from './components/shop-account/index.vue';
import {applications} from '../../resources';
import {ObjectId} from 'bson';
@Component({
  name: 'statistics',
  components: {
    Submenu,
    orderStatistics,
    deliveryOrderStatistics,
    billReconciliation,
    applicationAccount,
    shopAccount,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu: Array<{
    top: string;
    list: Array<{name: string; active: boolean}>;
  }> = [];
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private role = localStorage.getItem('role');
  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      )
    ).find(() => true);
    if (application?.spec.enableServices) {
      if (this.role === '财务') {
        this.submenu = [
          {
            top: '账户管理',
            list: [{name: '商家账户', active: true}],
          },
          {
            top: '',
            list: [
              {name: '账单对账', active: false},
              {name: '财务统计', active: false},
            ],
          },
        ];
        this.menu = this.$route.query.menu
          ? (this.$route.query.menu as string)
          : '商家账户';
      } else {
        if (application?.spec.enableServices.indexOf('配送') > -1) {
          this.submenu = [
            {
              top: '账户管理',
              list: [{name: '应用账户', active: false}],
            },
            {
              top: '',
              list: [
                {name: '账单对账', active: true},
                {name: '配送单统计', active: false},
                {name: '财务统计', active: false},
              ],
            },
          ];
          this.menu = this.$route.query.menu
            ? (this.$route.query.menu as string)
            : '应用账户';
        } else {
          this.submenu = [
            {
              top: '账户管理',
              list: [
                {name: '应用账户', active: true},
                {name: '商家账户', active: false},
              ],
            },
            {
              top: '',
              list: [
                {name: '账单对账', active: false},
                {name: '财务统计', active: false},
              ],
            },
          ];
          this.menu = this.$route.query.menu
            ? (this.$route.query.menu as string)
            : '应用账户';
        }
      }
    }
  }
}
