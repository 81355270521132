
import {Component, Vue} from 'vue-property-decorator';
import orderStatisticsIndex from './order-statistics-index.vue';
import commoditySales from './commodity-sales.vue';
@Component({
  name: 'orderStatistics',
  components: {
    orderStatisticsIndex,
    commoditySales,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
