
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {shops, shopSettlements} from '../../../../resources';
import {ObjectId} from 'bson';
import moment from 'moment';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {ShopSettlement} from '@/externals/MaxCI-ShopSettlement-v1';

// interface AccountList {
//   _id: {
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     time?: any;
//     type: '入账' | '出账';
//     sort: number;
//   };
//   shopAmount: number;
//   shopBalance: number;
// }
@Component({
  name: 'shopAccountBillList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private shopBalance = 0;
  private shopInfo: null | Shop = null;
  private time = '29';
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private shopId = ObjectId.createFromHexString(this.$route.query.id as string);
  private searchData = {
    type: '',
    time: [
      moment({hour: 0}).subtract({day: 29}).toDate(),
      moment({hour: 0}).toDate(),
    ],
  };
  private list: Array<ShopSettlement> = []; //列表
  private listLoading = false;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private total = 0;

  async created() {
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //查询列表
    this.updateList();

    //查询余额
    this.shopInfo =
      (
        await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('_id'),
              e => e.$eq(this.shopId),
            ),
          ),
        )
      ).find(() => true) ?? null;
    this.shopBalance = this.shopInfo?.spec.shopBalance ?? 0;
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.checkList();
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkList();
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查询已结算列表
  private async updateList() {
    try {
      this.listLoading = true;
      const list = await shopSettlements.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.type) {
                  query(
                    f => f('spec')('type'),
                    e => e.$eq(this.searchData.type as '出账' | '入账'),
                  );
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('status')('phase'),
                  e => e.$ne('待结算'),
                )(
                  f => f('spec')('shopId'),
                  e => e.$eq(this.shopId),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序')(
                    f => f('spec')('type'),
                    '升序',
                  ),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    } finally {
      this.listLoading = false;
    }
  }
  //领取时间
  private getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //获取图片
  private getImage() {
    return this.downloadUrl + this.shopInfo?.spec.logo ?? '';
  }
  //跳转详情
  private toDetail(row: ShopSettlement) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        shopId: this.$route.query.id,
        date: row.spec.date,
        billType: row.spec.type,
        price: row.spec.shopAmount.toString(),
      },
    });
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'shop',
      },
    });
  }
}
