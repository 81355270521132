
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  shops,
  shopSettlements,
  shopLabels,
  orders,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {EJSON} from 'bson';

interface AccountList extends Shop {
  inputNum: number;
  outputNum: number;
}
interface ShopValue extends ShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'shopAccountShopList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private dialogSearchLabel = false;
  private labelData: Array<ShopValue> = []; //设置标签数据
  private searchLabelData: Array<ShopValue> = []; //搜索标签数据
  private list: Array<AccountList> = []; //列表
  private listLoading = false;
  private total = 0;
  private searchData = {name: '', label: [] as Array<string>};
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';

  async created() {
    //查询列表
    this.updateList();
    //查询标签数据
    const list = (await shopLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        )(
          f => f('spec')('name'),
          e => e.$ne('个性标签'),
        ),
      ),
    )) as Array<ShopValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;
    this.searchLabelData = list;
  }
  //查询已结算列表
  private async updateList() {
    try {
      this.listLoading = true;
      const count = await shops.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            if (this.role === '店铺' || this.role === '财务') {
              match(
                f => f('_id'),
                e => e.$in(this.shopAuthority),
              );
            }
            if (this.searchData.label.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.searchData.label),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$count('count'),
      );
      this.total = count.length > 0 ? count[0].count : 0;
      let i = 0;
      while (i < Math.ceil(this.total / 20)) {
        i++;
        const list = await shops.find(stage =>
          stage
            .$match(match => {
              if (this.searchData.name) {
                match(
                  f => f('spec')('name'),
                  e => e.$regex(new RegExp(this.searchData.name)),
                );
              }
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('_id'),
                  e => e.$in(this.shopAuthority),
                );
              }
              if (this.searchData.label.length > 0) {
                match(
                  f => f('spec')('labels'),
                  e => e.$in(this.searchData.label),
                );
              }
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              );
              return match;
            })
            .$sort(sort =>
              sort(f => f('spec')('score'), '降序')(
                f => f('metadata')('creationTimestamp'),
                '降序',
              ),
            )
            .$skip((i - 1) * 20)
            .$limit(20),
        );
        //查询店铺待提现数量
        const shopSettlementData = await shopSettlements.find(stage =>
          stage
            .$match(match =>
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('spec')('shopId'),
                e => e.$in(list.map(v => v._id)),
              )(
                f => f('status')('phase'),
                e => e.$eq('待结算'),
              ),
            )
            .$facet(facet =>
              facet('outputNum', outputStage =>
                outputStage
                  .$match(match =>
                    match(
                      f => f('spec')('type'),
                      e => e.$eq('出账'),
                    ),
                  )
                  .$group(
                    e => e.$fieldPath(f => f('spec')('shopId')),
                    group =>
                      group('count', e => e.$sum(sum => sum.$literal(1))),
                  ),
              ),
            ),
        );
        const orderList = await orders.find(stage =>
          stage
            .$match(match =>
              match(
                f => f('status')('phase'),
                e => e.$in(['支付', '接单', '打包', '配送', '完成']),
              )(
                f => f('spec')('type'),
                e => e.$eq('商城'),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('spec')('shopId'),
                e => e.$in(list.map(v => v._id)),
              ),
            )
            .$group(
              e => e.$fieldPath(f => f('spec')('shopId')),
              group =>
                group('settlementAmount', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f => f('spec')('amount')('user')),
                  ),
                ),
            ),
        );
        const shopList = [] as Array<AccountList>;
        for (let item of list) {
          let inputNum = 0,
            outputNum = 0;
          if (shopSettlementData.length > 0) {
            for (let v of shopSettlementData[0].outputNum) {
              if (item._id.equals(v._id)) {
                outputNum = v.count;
                break;
              }
            }
          }
          for (let v of orderList) {
            if (item._id.equals(v._id)) {
              inputNum = v.settlementAmount;
              break;
            }
          }
          shopList.push({
            ...item,
            inputNum: inputNum,
            outputNum: outputNum,
          });
        }
        this.list.push(...shopList);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    } finally {
      this.listLoading = false;
    }
  }
  //搜索数据
  private checkList() {
    this.list = [];
    this.updateList();
  }
  //重置搜索标签
  private resetLabel() {
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        valItem.checked = false;
      });
    });
    this.searchData.label = [];
    this.checkList();
  }
  //搜索选中标签
  private searchSelectLabel() {
    const labels = [] as Array<string>;
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.searchData.label = labels;
    this.checkList();
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //获取图片
  private getImage(img: string) {
    return this.downloadUrl + img;
  }
  //异常单详情
  private toUnusual(type: string, row: AccountList) {
    if (type === '结算') {
      this.$router.push({
        query: {
          menu: '账单对账',
          shopId: row._id.toHexString(),
        },
      });
    } else {
      this.$router.push({
        query: {
          menu: this.$route.query.menu,
          type: 'unusualDetail',
          id: row._id.toHexString(),
        },
      });
    }
  }
  //跳转详情
  private toDetail(row: AccountList) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'bill',
        id: row._id.toHexString(),
      },
    });
  }
}
