
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {deliveryOrders} from '../../../../resources';
import {ObjectId} from 'bson';
import moment from 'moment';
import {ObjectFields, SystemVariable} from '@warehouse/client';
import {Order} from '@/externals/MaxCI-Order-v1';
import {EJSON} from 'bson';
import {DeliveryUserInfo1} from '@/externals/MaxCI-DeliveryOrder-v1';

interface SummaryDataSpec {
  deliveryOrderNum: number;
  noticeNum: number;
}
interface RiderList {
  _id: {
    riderId: ObjectId;
    date: string;
  };
  riderInfo?: DeliveryUserInfo1;
  count: number;
}
@Component({
  name: 'fundsBillList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private mallShow = false; //商城功能是否开启
  private agentShow = false; //跑腿功能是否开启
  private usedShow = false; //二手功能是否开启
  private sortVal = '';
  private sortType = '';
  private groupType = 'rider';
  private orderType = '全部'; //订单类型
  private loading = false;
  private total = 0;
  private list: Array<RiderList> = [];
  private listLoading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  private listParams = {
    page: 1,
    limit: 10,
  };
  private time = '6';
  private searchData = {
    shopName: '',
    time: [
      moment({hour: 0})
        .subtract({day: Number(6)})
        .toDate(),
      moment({hour: 0}).toDate(),
    ],
  };
  private summaryData: null | SummaryDataSpec = null;
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    this.checkSummary();
    this.checkRiderOrder();
  }
  private async checkRiderOrder() {
    const list = await deliveryOrders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('status')('phase'),
                e => e.$eq('完成'),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$gte(
                      moment(this.searchData.time[0])
                        .utcOffset(8)
                        .startOf('day')
                        .toDate(),
                    ),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .utcOffset(8)
                        .endOf('day')
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('riderData', riderStage => {
            const triderStage = riderStage.$group(
              e =>
                e.$object(object => {
                  const tobject = object('riderId', e =>
                    e.$fieldPath(f => f('spec')('riderId')),
                  );
                  if (this.groupType === 'time') {
                    tobject('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    );
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  return tobject as any as ObjectFields<
                    Order,
                    SystemVariable<Order>,
                    {
                      riderId: ObjectId;
                      date: string;
                    }
                  >;
                }),
              group =>
                group('riderInfo', e =>
                  e.$first(first =>
                    first.$fieldPath(f => f('spec')('riderInfo')),
                  ),
                )('count', e => e.$sum(sum => sum.$literal(1))),
            );
            if (this.groupType === 'time') {
              triderStage.$sort(sort =>
                sort(f => f('_id')('date'), '降序')(
                  f => f('_id')('riderId'),
                  '降序',
                ),
              );
            } else {
              if (this.sortVal === '配送单数') {
                triderStage.$sort(sort =>
                  sort(f => f('count'), this.sortType as '升序' | '降序')(
                    f => f('_id')('riderId'),
                    '降序',
                  ),
                );
              }
            }
            triderStage.$skip(
              (this.listParams.page - 1) * this.listParams.limit,
            );
            triderStage.$limit(this.listParams.limit);
            return triderStage;
          })('count', countStage =>
            countStage
              .$group(e =>
                e.$object(object => {
                  object('riderId', e =>
                    e.$fieldPath(f => f('spec')('riderId')),
                  );
                  if (this.groupType === 'time') {
                    object('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    );
                  }
                  return object;
                }),
              )
              .$count('count'),
          ),
        ),
    );
    this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
    this.list = list[0].riderData;
  }
  //配送送达单数，手动完成单数，语音通知完成单数
  private async checkSummary() {
    const deliveryOrder = await deliveryOrders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('status')('phase'),
                e =>
                  e.$in([
                    '下单',
                    '接单',
                    '分拣',
                    '取货',
                    '到店',
                    '完成',
                    '取消',
                  ]),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$gte(
                      moment(this.searchData.time[0])
                        .utcOffset(8)
                        .startOf('day')
                        .toDate(),
                    ),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .utcOffset(8)
                        .endOf('day')
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('deliveryOrderNum', deliveryOrderNum =>
            deliveryOrderNum.$group(
              e => e.$literal(null),
              group => group('count', e => e.$sum(sum => sum.$literal(1))),
            ),
          )('noticeNum', noticeNum =>
            noticeNum
              .$match(match =>
                match(
                  f => f('status')('notice'),
                  e => e.$eq('成功'),
                ),
              )
              .$group(
                e => e.$literal(null),
                group => group('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          ),
        ),
    );
    if (deliveryOrder.length > 0) {
      this.summaryData = {
        deliveryOrderNum:
          deliveryOrder[0].deliveryOrderNum.length > 0
            ? deliveryOrder[0].deliveryOrderNum[0].count
            : 0,
        noticeNum:
          deliveryOrder[0].noticeNum.length > 0
            ? deliveryOrder[0].noticeNum[0].count
            : 0,
      };
    }
  }
  //排序
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sortChange(column: any) {
    this.sortVal = column.column.label;
    this.sortType = column.order === 'ascending' ? '升序' : '降序';
    this.checkRiderOrder();
  }
  //切换商城搜索类型
  private changeGroupType() {
    this.checkRiderOrder();
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkSummary();
    this.checkRiderOrder();
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.listParams.page = 1;
    this.checkSummary();
    this.checkRiderOrder();
  }
  //跳转详情
  private to_detail(num: number) {
    if (num > 0) {
      this.$router.push({
        path:
          '/application/' + this.$route.params.applicationId + '/order/index',
        query: {
          menu: '配送单列表',
          type: 'list',
          isNotice: '是',
          ...(this.searchData.time && this.searchData.time.length > 0
            ? {
                startTime: moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                endTime: moment(this.searchData.time[1]).format('YYYY-MM-DD'),
              }
            : {}),
        },
      });
    }
  }
}
