
import {Component, Vue} from 'vue-property-decorator';
import billReconciliationList from './list.vue';
import billReconciliationDetail from './detail.vue';
@Component({
  name: 'billReconciliation',
  components: {
    billReconciliationList,
    billReconciliationDetail,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
