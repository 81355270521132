
import {Component, Vue} from 'vue-property-decorator';
import {orders, shops, shopSettlements} from '../../../../resources';
import lodash from 'lodash';
import {ObjectId} from 'bson';
import Pagination from '@/components/Pagination/index.vue';
interface SettleAccounts {
  _id: ObjectId;
  user: number;
  tecServiceAmount: number;
  allowanceAmount: number;
  deliveryServiceAmount: number;
  applicationAmount: number;
  shopAmount: number;
}
interface WithdrawalList {
  _id: ObjectId;
  applicationAmount: number;
  shopAmount: number;
}
@Component({
  name: 'applicationAccountDetail',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private billType = this.$route.query.billType === '入账' ? '结算' : '提现';
  private date = this.$route.query.date as string;
  private price = this.$route.query.price ? Number(this.$route.query.price) : 0;
  private settleAccountsList: Array<SettleAccounts> = []; //结算列表
  private withdrawalList: Array<WithdrawalList> = []; //提现列表
  private listLoading = false;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private total = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {}; //店铺信息
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );

  async created() {
    //查询列表
    this.updateList();
  }
  private async updateList() {
    try {
      this.listLoading = true;
      if (this.billType === '结算') {
        const list = await orders.find(stage =>
          stage
            .$match(match =>
              match(
                f => f('spec')('serial')('date'),
                e => e.$eq(this.date),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('spec')('type'),
                e => e.$eq('商城'),
              ),
            )
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$group(
                    e => e.$fieldPath(f => f('spec')('shopId')),
                    group =>
                      group('user', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f => f('spec')('amount')('user')),
                        ),
                      )('tecServiceAmount', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('status')('settlement')('input')(
                              'tecServiceAmount',
                            ),
                          ),
                        ),
                      )('allowanceAmount', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('status')('settlement')('input')(
                              'allowanceAmount',
                            ),
                          ),
                        ),
                      )('deliveryServiceAmount', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('status')('settlement')('input')(
                              'deliveryServiceAmount',
                            ),
                          ),
                        ),
                      )('applicationAmount', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('status')('settlement')('input')(
                              'applicationAmount',
                            ),
                          ),
                        ),
                      )('shopAmount', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('status')('settlement')('input')('shopAmount'),
                          ),
                        ),
                      ),
                  )
                  .$skip((this.listParams.page - 1) * this.listParams.limit)
                  .$limit(this.listParams.limit),
              )('count', countStage =>
                countStage
                  .$group(e => e.$fieldPath(f => f('spec')('shopId')))
                  .$count('count'),
              ),
            ),
        );
        //查询店铺信息 用户信息
        let shopIdData: Array<ObjectId> = [];
        list[0].table.forEach(item => {
          shopIdData.push(item._id);
        });
        //查询店铺信息
        shopIdData = Array.from(new Set(shopIdData));
        const shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        );
        this.shopInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shopData.map(v => v._id.toHexString()) as any,
          shopData,
        );
        this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
        this.settleAccountsList = list[0].table;
      } else {
        const list = await shopSettlements.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  query(
                    f => f('spec')('type'),
                    e => e.$eq('出账'),
                  );
                  query(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  )(
                    f => f('status')('phase'),
                    e => e.$ne('待结算'),
                  )(
                    f => f('spec')('date'),
                    e => e.$eq(this.date),
                  );
                  return query;
                });
                return and;
              }),
            )
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$skip((this.listParams.page - 1) * this.listParams.limit)
                  .$limit(this.listParams.limit),
              )('count', countStage => countStage.$count('count')),
            ),
        );
        const orderList = await orders.find(stage =>
          stage
            .$match(match =>
              match(
                f => f('status')('settlement')('output')('settlementId'),
                e => e.$in(list[0].table.map(v => v._id)),
              ),
            )
            .$group(
              e => e.$fieldPath(f => f('spec')('shopId')),
              group =>
                group('applicationAmount', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('status')('settlement')('output')('applicationAmount'),
                    ),
                  ),
                )('shopAmount', e =>
                  e.$sum(sum =>
                    sum.$fieldPath(f =>
                      f('status')('settlement')('output')('shopAmount'),
                    ),
                  ),
                ),
            ),
        );
        //查询店铺信息 用户信息
        let shopIdData: Array<ObjectId> = [];
        orderList.forEach(item => {
          shopIdData.push(item._id);
        });
        //查询店铺信息
        shopIdData = Array.from(new Set(shopIdData));
        const shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        );
        this.shopInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shopData.map(v => v._id.toHexString()) as any,
          shopData,
        );
        this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
        this.withdrawalList = orderList;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    } finally {
      this.listLoading = false;
    }
  }
  //获取店铺信息
  private getShopInfo(id: ObjectId) {
    let name = '';
    if (this.shopInfoData[id.toHexString()]) {
      name = this.shopInfoData[id.toHexString()].spec.name;
    }
    return name;
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
}
