
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {shops, shopSettlements} from '../../../../resources';
import {ObjectId} from 'bson';
import moment from 'moment';

interface AccountList {
  _id: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    time?: any;
    type: '入账' | '出账';
    sort: number;
  };
  applicationAmount: number;
  applicationBalance: number;
}
@Component({
  name: 'applicationAccountList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private applicationBalance = 0;
  private time = '29';
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private searchData = {
    type: '',
    time: [
      moment({hour: 0}).subtract({day: 29}).toDate(),
      moment({hour: 0}).toDate(),
    ],
  };
  private list: Array<AccountList> = []; //列表
  private listLoading = false;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private total = 0;

  async created() {
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //查询列表
    this.updateList();

    //查询余额
    this.applicationBalance =
      (
        await shops.find(stage =>
          stage
            .$match(match =>
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            )
            .$group(
              e => e.$literal(null),
              group =>
                group('applicationBalance', e =>
                  e.$sum(sum =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sum.$fieldPath(f => f('spec')('applicationBalance')),
                  ),
                ),
            ),
        )
      ).find(() => true)?.applicationBalance ?? 0;
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.checkList();
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkList();
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查询已结算列表
  private async updateList() {
    try {
      this.listLoading = true;
      const list = await shopSettlements.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.type) {
                  query(
                    f => f('spec')('type'),
                    e => e.$eq(this.searchData.type as '出账' | '入账'),
                  );
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('status')('phase'),
                  e => e.$ne('待结算'),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('spec')('date'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1]).format('YYYY-MM-DD'),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$group(
                  e =>
                    e.$object(object =>
                      object('type', e => e.$fieldPath(f => f('spec')('type')))(
                        'time',
                        e => e.$fieldPath(f => f('spec')('date')),
                      )('sort', e =>
                        e.$cond(
                          _if =>
                            _if.$eq(
                              e => e.$fieldPath(f => f('spec')('type')),
                              e => e.$literal('入账'),
                            ),
                          _then => _then.$literal(1),
                          _else => _else.$literal(0),
                        ),
                      ),
                    ),
                  group =>
                    group('applicationAmount', e =>
                      e.$sum(sum =>
                        sum.$fieldPath(f => f('spec')('applicationAmount')),
                      ),
                    )('applicationBalance', e =>
                      e.$sum(sum =>
                        sum.$fieldPath(f => f('spec')('applicationBalance')),
                      ),
                    )('creationTimestamp', e =>
                      e.$first(p =>
                        p.$fieldPath(f => f('metadata')('creationTimestamp')),
                      ),
                    ),
                )
                .$sort(sort =>
                  sort(f => f('creationTimestamp'), '降序')(
                    f => f('_id')('sort'),
                    '升序',
                  ),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage =>
              countStage
                .$group(e =>
                  e.$object(object =>
                    object('type', e => e.$fieldPath(f => f('spec')('type')))(
                      'time',
                      e => e.$fieldPath(f => f('spec')('date')),
                    )('sort', e =>
                      e.$cond(
                        _if =>
                          _if.$eq(
                            e => e.$fieldPath(f => f('spec')('type')),
                            e => e.$literal('入账'),
                          ),
                        _then => _then.$literal(1),
                        _else => _else.$literal(0),
                      ),
                    ),
                  ),
                )
                .$count('count'),
            ),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    } finally {
      this.listLoading = false;
    }
  }
  //领取时间
  private getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //跳转详情
  private toDetail(row: AccountList) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        date: row._id.time,
        billType: row._id.type,
        price: row.applicationAmount.toString(),
      },
    });
  }
}
