
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {orders, shops, applications} from '../../../../resources';
import {ObjectId} from 'bson';
import moment from 'moment';
import xlsx from 'xlsx';
import * as echarts from 'echarts';
import {ObjectFields, SystemVariable} from '@warehouse/client';
import {Order} from '@/externals/MaxCI-Order-v1';
import lodash from 'lodash';
import {EJSON} from 'bson';

interface SummaryDataSpec {
  agentSubsidy: number;
  usedSubsidy: number;
  mallSubsidy: number;
  count: number;
  shopRefundAmount: number;
  otherRefundAmount: number;
  refundCount: number;
  user: number;
  _id: null;
}
interface MallList {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _id: any;
  count: number;
  shopRefundAmount: number;
  otherRefundAmount: number;
  refundCount: number;
  user: number;
}
@Component({
  name: 'fundsBillList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private mallShow = false; //商城功能是否开启
  private agentShow = false; //跑腿功能是否开启
  private usedShow = false; //二手功能是否开启
  private sortVal = '';
  private sortType = '';
  private groupType = 'shop';
  private orderType = '全部'; //订单类型
  private loading = false;
  private total = 0;
  private list: Array<MallList> = [];
  private listLoading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  private listParams = {
    page: 1,
    limit: 10,
  };
  private time = '6';
  private searchData = {
    shopName: '',
    time: [
      moment({hour: 0})
        .subtract({day: Number(6)})
        .toDate(),
      moment({hour: 0}).toDate(),
    ],
  };
  private summaryData: null | SummaryDataSpec = null;
  private mallSummaryData: null | MallList = null;
  private xData: Array<string> = [];
  private yUser: Array<number> = [];
  private yCount: Array<number> = [];
  private yRefundAmount: Array<number> = [];
  private yRefundCount: Array<number> = [];
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';
  async created() {
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          );
          return match;
        }),
      )
    ).find(() => true);
    this.checkSummary();
    this.checkCharts();
    if (application?.spec.enableServices) {
      application?.spec.enableServices.forEach(item => {
        if (item === '商城') {
          this.mallShow = true;
          this.checkMall();
        }
        if (item === '二手') {
          this.usedShow = true;
        }
        if (item === '跑腿') {
          this.agentShow = true;
        }
      });
    }
  }
  mounted() {
    const echartbox = document.getElementById('echartbox') as HTMLCanvasElement;
    window.onresize = () => {
      return (() => {
        echarts.init(echartbox).resize();
      })();
    };
  }
  //排序
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sortChange(column: any) {
    this.sortVal = column.column.label;
    this.sortType = column.order === 'ascending' ? '升序' : '降序';
    this.checkMall();
  }
  //搜索店铺
  private searchShopName() {
    this.listParams.page = 1;
    this.checkMall();
    this.checkSummary();
  }
  //切换商城搜索类型
  private changeGroupType() {
    this.listParams.page = 1;
    this.checkMall();
  }
  //切换订单类型
  private changeOrderType() {
    this.checkCharts();
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkSummary();
    this.checkCharts();
    this.checkMall();
  }
  //获取店铺信息
  private getShopInfo(id: ObjectId) {
    return this.shopInfoData[id.toHexString()] &&
      this.shopInfoData[id.toHexString()].spec
      ? this.shopInfoData[id.toHexString()].spec.name
      : '--';
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.listParams.page = 1;
    this.checkSummary();
    this.checkCharts();
    this.checkMall();
  }
  //查询汇总
  private async checkSummary() {
    //店铺名称搜索
    let searchShop: Array<ObjectId> = [];
    if (this.searchData.shopName) {
      const shop = await shops.find(stage =>
        stage.$match(match => {
          if (this.role === '店铺' || this.role === '财务') {
            match(
              f => f('_id'),
              e => e.$in(this.shopAuthority),
            );
          }
          match(
            f => f('spec')('name'),
            e => e.$regex(new RegExp(this.searchData.shopName)),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          );
          return match;
        }),
      );
      searchShop = shop.map(v => v._id);
    }
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              )(
                f => f('status')('payInfo'),
                e => e.$exists(true),
              );
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('spec')('shopId'),
                  e => e.$in(this.shopAuthority),
                );
              }
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e => e.$gte(moment(this.searchData.time[0]).toDate()),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .add({day: 1})
                        .subtract({millisecond: 1})
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('summaryData', summaryStage =>
            summaryStage.$group(
              e => e.$literal(null),
              group =>
                group('user', e =>
                  e.$sum(sum =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sum.$fieldPath(f => f('spec')('amount')('user')),
                  ),
                )('count', e => e.$sum(sum => sum.$literal(1)))(
                  'shopRefundAmount',
                  e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$eq(
                            e => e.$fieldPath(f => f('spec')('type')),
                            e => e.$literal('商城'),
                          ),
                        _then =>
                          _then.$fieldPath(f => f('spec')('refund')('user')),
                        _else => _else.$literal(0),
                      ),
                    ),
                )('otherRefundAmount', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$or(
                          e =>
                            e.$and(
                              e =>
                                e.$eq(
                                  e => e.$fieldPath(f => f('status')('phase')),
                                  e => e.$literal('退款'),
                                ),
                              e =>
                                e.$ne(
                                  e => e.$fieldPath(f => f('spec')('type')),
                                  e => e.$literal('商城'),
                                ),
                            ),
                          e =>
                            e.$and(
                              e =>
                                e.$eq(
                                  e => e.$fieldPath(f => f('status')('phase')),
                                  e => e.$literal('取消'),
                                ),
                              e => e.$fieldPath(f => f('status')('payInfo')),
                              e =>
                                e.$ne(
                                  e => e.$fieldPath(f => f('spec')('type')),
                                  e => e.$literal('商城'),
                                ),
                            ),
                        ),
                      _then =>
                        _then.$fieldPath(f => f('spec')('amount')('user')),
                      _else => _else.$literal(0),
                    ),
                  ),
                )('refundCount', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$or(
                          e =>
                            e.$eq(
                              e => e.$fieldPath(f => f('status')('phase')),
                              e => e.$literal('退款'),
                            ),
                          e =>
                            e.$and(
                              e =>
                                e.$eq(
                                  e => e.$fieldPath(f => f('status')('phase')),
                                  e => e.$literal('取消'),
                                ),
                              e => e.$fieldPath(f => f('status')('payInfo')),
                            ),
                        ),
                      _then => _then.$literal(1),
                      _else => _else.$literal(0),
                    ),
                  ),
                )('mallSubsidy', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$eq(
                          e => e.$fieldPath(f => f('spec')('type')),
                          e => e.$literal('商城'),
                        ),
                      _then =>
                        _then.$fieldPath(f =>
                          f('spec')('amount')('application'),
                        ),
                      _else => _else.$literal(0),
                    ),
                  ),
                )('agentSubsidy', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$eq(
                          e => e.$fieldPath(f => f('spec')('type')),
                          e => e.$literal('跑腿'),
                        ),
                      _then =>
                        _then.$fieldPath(f =>
                          f('spec')('amount')('application'),
                        ),
                      _else => _else.$literal(0),
                    ),
                  ),
                )('usedSubsidy', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$eq(
                          e => e.$fieldPath(f => f('spec')('type')),
                          e => e.$literal('二手'),
                        ),
                      _then =>
                        _then.$fieldPath(f =>
                          f('spec')('amount')('application'),
                        ),
                      _else => _else.$literal(0),
                    ),
                  ),
                ),
            ),
          )('mallSummaryData', mallSummaryStage =>
            mallSummaryStage
              .$match(match => {
                match(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                );
                if (searchShop.length > 0) {
                  match(
                    f => f('spec')('shopId'),
                    e => e.$in(searchShop),
                  );
                }
                return match;
              })
              .$group(
                e => e.$literal(null),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1)))(
                    'shopRefundAmount',
                    e =>
                      e.$sum(sum =>
                        sum.$cond(
                          _if =>
                            _if.$eq(
                              e => e.$fieldPath(f => f('spec')('type')),
                              e => e.$literal('商城'),
                            ),
                          _then =>
                            _then.$fieldPath(f => f('spec')('refund')('user')),
                          _else => _else.$literal(0),
                        ),
                      ),
                  )('otherRefundAmount', e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$or(
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('退款'),
                                  ),
                                e =>
                                  e.$ne(
                                    e => e.$fieldPath(f => f('spec')('type')),
                                    e => e.$literal('商城'),
                                  ),
                              ),
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('取消'),
                                  ),
                                e => e.$fieldPath(f => f('status')('payInfo')),
                                e =>
                                  e.$ne(
                                    e => e.$fieldPath(f => f('spec')('type')),
                                    e => e.$literal('商城'),
                                  ),
                              ),
                          ),
                        _then =>
                          _then.$fieldPath(f => f('spec')('amount')('user')),
                        _else => _else.$literal(0),
                      ),
                    ),
                  )('refundCount', e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$or(
                            e =>
                              e.$eq(
                                e => e.$fieldPath(f => f('status')('phase')),
                                e => e.$literal('退款'),
                              ),
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('取消'),
                                  ),
                                e => e.$fieldPath(f => f('status')('payInfo')),
                              ),
                          ),
                        _then => _then.$literal(1),
                        _else => _else.$literal(0),
                      ),
                    ),
                  ),
              ),
          ),
        ),
    );
    this.summaryData = list[0].summaryData[0];
    this.mallSummaryData = list[0].mallSummaryData[0];
  }
  //图表数据
  private async checkCharts() {
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              )(
                f => f('status')('payInfo'),
                e => e.$exists(true),
              );
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('spec')('shopId'),
                  e => e.$in(this.shopAuthority),
                );
              }
              if (this.orderType !== '全部') {
                query(
                  f => f('spec')('type'),
                  e =>
                    e.$eq(this.orderType as '商城' | '跑腿' | '提现' | '二手'),
                );
              }
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e => e.$gte(moment(this.searchData.time[0]).toDate()),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .add({day: 1})
                        .subtract({millisecond: 1})
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('chartsData', chartsStage =>
            chartsStage
              .$group(
                e =>
                  e.$object(object =>
                    object('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    ),
                  ),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1)))(
                    'shopRefundAmount',
                    e =>
                      e.$sum(sum =>
                        sum.$cond(
                          _if =>
                            _if.$eq(
                              e => e.$fieldPath(f => f('spec')('type')),
                              e => e.$literal('商城'),
                            ),
                          _then =>
                            _then.$fieldPath(f => f('spec')('refund')('user')),
                          _else => _else.$literal(0),
                        ),
                      ),
                  )('otherRefundAmount', e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$or(
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('退款'),
                                  ),
                                e =>
                                  e.$ne(
                                    e => e.$fieldPath(f => f('spec')('type')),
                                    e => e.$literal('商城'),
                                  ),
                              ),
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('取消'),
                                  ),
                                e => e.$fieldPath(f => f('status')('payInfo')),
                                e =>
                                  e.$ne(
                                    e => e.$fieldPath(f => f('spec')('type')),
                                    e => e.$literal('商城'),
                                  ),
                              ),
                          ),
                        _then =>
                          _then.$fieldPath(f => f('spec')('amount')('user')),
                        _else => _else.$literal(0),
                      ),
                    ),
                  )('refundCount', e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$or(
                            e =>
                              e.$eq(
                                e => e.$fieldPath(f => f('status')('phase')),
                                e => e.$literal('退款'),
                              ),
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('取消'),
                                  ),
                                e => e.$fieldPath(f => f('status')('payInfo')),
                              ),
                          ),
                        _then => _then.$literal(1),
                        _else => _else.$literal(0),
                      ),
                    ),
                  ),
              )
              .$sort(sort => sort(f => f('_id')('date'), '升序')),
          ),
        ),
    );
    this.xData = list[0].chartsData.map(v => v._id.date as string);
    this.yUser = list[0].chartsData.map(v => v.user / 100);
    this.yCount = list[0].chartsData.map(v => v.count);
    this.yRefundAmount = list[0].chartsData.map(
      v => (v.shopRefundAmount + v.otherRefundAmount) / 100,
    );
    this.yRefundCount = list[0].chartsData.map(v => v.refundCount);
    const chart = document.getElementById('echartbox') as HTMLCanvasElement;
    const myChart = echarts.init(chart);

    // 绘制图表
    myChart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        data: this.xData,
        max: this.xData[this.xData.length - 1],
        axisLabel: {
          showMaxLabel: true,
        },
        boundaryGap: false,
      },
      yAxis: [
        {
          type: 'value',
          name: '交易金额',
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
          axisLabel: {
            show: false, //隐藏值
          },
          position: 'left',
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
            },
          },
        },
        {
          type: 'value',
          name: '订单数',
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
          axisLabel: {
            show: false, //隐藏值
          },
          position: 'right',
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
            },
          },
        },
      ],
      legend: {
        orient: 'horizontal',
        padding: [0, 0, 10, 0],
        data: ['交易金额', '订单数', '退款金额', '退款单数'],
      },
      series: [
        {
          name: '交易金额',
          data: this.yUser,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 0,
          itemStyle: {
            color: '#6DD181',
          },
        },
        {
          name: '订单数',
          data: this.yCount,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 1,
          itemStyle: {
            color: '#657AFF',
          },
        },
        {
          name: '退款金额',
          data: this.yRefundAmount,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 0,
          itemStyle: {
            color: '#FF9B7B',
          },
        },
        {
          name: '退款单数',
          data: this.yRefundCount,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 1,
          itemStyle: {
            color: '#FFC811',
          },
        },
      ],
    });
  }
  //查询商城汇总
  private async checkMall() {
    //店铺名称搜索
    let searchShop: Array<ObjectId> = [];
    if (this.searchData.shopName) {
      const shop = await shops.find(stage =>
        stage.$match(match => {
          if (this.role === '店铺' || this.role === '财务') {
            match(
              f => f('_id'),
              e => e.$in(this.shopAuthority),
            );
          }
          match(
            f => f('spec')('name'),
            e => e.$regex(new RegExp(this.searchData.shopName)),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          );
          return match;
        }),
      );
      searchShop = shop.map(v => v._id);
    }
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('spec')('shopId'),
                  e => e.$in(this.shopAuthority),
                );
              } else {
                if (searchShop.length > 0) {
                  query(
                    f => f('spec')('shopId'),
                    e => e.$in(searchShop),
                  );
                }
              }
              query(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              )(
                f => f('spec')('type'),
                e => e.$eq('商城'),
              )(
                f => f('status')('payInfo'),
                e => e.$exists(true),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e => e.$gte(moment(this.searchData.time[0]).toDate()),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .add({day: 1})
                        .subtract({millisecond: 1})
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('mallData', mallStage => {
            const tmallStage = mallStage.$group(
              e =>
                e.$object(object => {
                  const tobject = object('shopId', e =>
                    e.$fieldPath(f => f('spec')('shopId')),
                  );
                  if (this.groupType === 'time') {
                    tobject('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    );
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  return tobject as any as ObjectFields<
                    Order,
                    SystemVariable<Order>,
                    {
                      shopId: ObjectId;
                      date: string;
                    }
                  >;
                }),
              group =>
                group('user', e =>
                  e.$sum(sum =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sum.$fieldPath(f => f('spec')('amount')('user')),
                  ),
                )('count', e => e.$sum(sum => sum.$literal(1)))(
                  'shopRefundAmount',
                  e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$eq(
                            e => e.$fieldPath(f => f('spec')('type')),
                            e => e.$literal('商城'),
                          ),
                        _then =>
                          _then.$fieldPath(f => f('spec')('refund')('user')),
                        _else => _else.$literal(0),
                      ),
                    ),
                )('otherRefundAmount', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$or(
                          e =>
                            e.$and(
                              e =>
                                e.$eq(
                                  e => e.$fieldPath(f => f('status')('phase')),
                                  e => e.$literal('退款'),
                                ),
                              e =>
                                e.$ne(
                                  e => e.$fieldPath(f => f('spec')('type')),
                                  e => e.$literal('商城'),
                                ),
                            ),
                          e =>
                            e.$and(
                              e =>
                                e.$eq(
                                  e => e.$fieldPath(f => f('status')('phase')),
                                  e => e.$literal('取消'),
                                ),
                              e => e.$fieldPath(f => f('status')('payInfo')),
                              e =>
                                e.$ne(
                                  e => e.$fieldPath(f => f('spec')('type')),
                                  e => e.$literal('商城'),
                                ),
                            ),
                        ),
                      _then =>
                        _then.$fieldPath(f => f('spec')('amount')('user')),
                      _else => _else.$literal(0),
                    ),
                  ),
                )('refundCount', e =>
                  e.$sum(sum =>
                    sum.$cond(
                      _if =>
                        _if.$or(
                          e =>
                            e.$eq(
                              e => e.$fieldPath(f => f('status')('phase')),
                              e => e.$literal('退款'),
                            ),
                          e =>
                            e.$and(
                              e =>
                                e.$eq(
                                  e => e.$fieldPath(f => f('status')('phase')),
                                  e => e.$literal('取消'),
                                ),
                              e => e.$fieldPath(f => f('status')('payInfo')),
                            ),
                        ),
                      _then => _then.$literal(1),
                      _else => _else.$literal(0),
                    ),
                  ),
                ),
            );
            if (this.groupType === 'time') {
              tmallStage.$sort(sort =>
                sort(f => f('_id')('date'), '降序')(
                  f => f('_id')('shopId'),
                  '降序',
                ),
              );
            } else {
              if (this.sortVal === '订单数') {
                tmallStage.$sort(sort =>
                  sort(f => f('count'), this.sortType as '升序' | '降序')(
                    f => f('_id')('shopId'),
                    '降序',
                  ),
                );
              }
              if (this.sortVal === '交易金额(￥)') {
                tmallStage.$sort(sort =>
                  sort(f => f('user'), this.sortType as '升序' | '降序')(
                    f => f('_id')('shopId'),
                    '降序',
                  ),
                );
              }
            }
            tmallStage.$skip(
              (this.listParams.page - 1) * this.listParams.limit,
            );
            tmallStage.$limit(this.listParams.limit);
            return tmallStage;
          })('count', countStage =>
            countStage
              .$group(e =>
                e.$object(object => {
                  object('shopId', e => e.$fieldPath(f => f('spec')('shopId')));
                  if (this.groupType === 'time') {
                    object('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    );
                  }
                  return object;
                }),
              )
              .$count('count'),
          ),
        ),
    );
    let shopIdData: Array<ObjectId> = [];
    list[0].mallData.forEach(item => {
      shopIdData.push(item._id.shopId);
    });
    shopIdData = Array.from(new Set(shopIdData));
    const shopData = await shops.find(stage =>
      stage.$match(match =>
        match(
          f => f('_id'),
          e => e.$in(shopIdData),
        )(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        ),
      ),
    );
    this.shopInfoData = lodash.zipObject(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      shopData.map(v => v._id.toHexString()) as any,
      shopData,
    );
    this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
    this.list = list[0].mallData;
  }
  //跳转商品详情
  private toShopDetail(shopId: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        shopId: shopId.toHexString(),
        ...(this.searchData.time && this.searchData.time.length > 0
          ? {
              startTime: moment(this.searchData.time[0]).format('YYYY-MM-DD'),
              endTime: moment(this.searchData.time[1]).format('YYYY-MM-DD'),
            }
          : {}),
      },
    });
  }
  // 商城导出明细
  private async mallExportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      //店铺名称搜索
      let searchShop: Array<ObjectId> = [];
      if (this.searchData.shopName) {
        const shop = await shops.find(stage =>
          stage.$match(match => {
            if (this.role === '店铺' || this.role === '财务') {
              match(
                f => f('_id'),
                e => e.$in(this.shopAuthority),
              );
            }
            match(
              f => f('spec')('name'),
              e => e.$regex(new RegExp(this.searchData.shopName)),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        );
        searchShop = shop.map(v => v._id);
      }
      const count = (
        await orders.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  } else {
                    if (searchShop.length > 0) {
                      query(
                        f => f('spec')('shopId'),
                        e => e.$in(searchShop),
                      );
                    }
                  }
                  query(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  )(
                    f => f('spec')('type'),
                    e => e.$eq('商城'),
                  )(
                    f => f('status')('payInfo'),
                    e => e.$exists(true),
                  );
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('count', countStage =>
                countStage
                  .$group(e =>
                    e.$object(object => {
                      object('shopId', e =>
                        e.$fieldPath(f => f('spec')('shopId')),
                      );
                      if (this.groupType === 'time') {
                        object('date', e =>
                          e.$dateToString(
                            date =>
                              date.$fieldPath(f =>
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                f('metadata')('creationTimestamp'),
                              ),
                            format => format.$literal('%Y-%m-%d'),
                            timezone => timezone.$literal('+08'),
                          ),
                        );
                      }
                      return object;
                    }),
                  )
                  .$count('count'),
              ),
            ),
        )
      )[0].count[0].count.valueOf();
      if (this.groupType === 'time') {
        reportList.push([
          '日期',
          '店铺名称',
          '订单数',
          '交易金额(￥)',
          '退款单数',
          '退款金额(￥)',
        ]);
      } else {
        reportList.push([
          '店铺名称',
          '订单数',
          '交易金额(￥)',
          '退款单数',
          '退款金额(￥)',
        ]);
      }

      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await orders.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  } else {
                    if (searchShop.length > 0) {
                      query(
                        f => f('spec')('shopId'),
                        e => e.$in(searchShop),
                      );
                    }
                  }
                  query(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  )(
                    f => f('spec')('type'),
                    e => e.$eq('商城'),
                  )(
                    f => f('status')('payInfo'),
                    e => e.$exists(true),
                  );
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('mallData', mallStage => {
                const tmallStage = mallStage.$group(
                  e =>
                    e.$object(object => {
                      const tobject = object('shopId', e =>
                        e.$fieldPath(f => f('spec')('shopId')),
                      );
                      if (this.groupType === 'time') {
                        tobject('date', e =>
                          e.$dateToString(
                            date =>
                              date.$fieldPath(f =>
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                f('metadata')('creationTimestamp'),
                              ),
                            format => format.$literal('%Y-%m-%d'),
                            timezone => timezone.$literal('+08'),
                          ),
                        );
                      }
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      return tobject as any as ObjectFields<
                        Order,
                        SystemVariable<Order>,
                        {
                          shopId: ObjectId;
                          date: string;
                        }
                      >;
                    }),
                  group =>
                    group('user', e =>
                      e.$sum(sum =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sum.$fieldPath(f => f('spec')('amount')('user')),
                      ),
                    )('count', e => e.$sum(sum => sum.$literal(1)))(
                      'shopRefundAmount',
                      e =>
                        e.$sum(sum =>
                          sum.$cond(
                            _if =>
                              _if.$eq(
                                e => e.$fieldPath(f => f('spec')('type')),
                                e => e.$literal('商城'),
                              ),
                            _then =>
                              _then.$fieldPath(f =>
                                f('spec')('refund')('user'),
                              ),
                            _else => _else.$literal(0),
                          ),
                        ),
                    )('otherRefundAmount', e =>
                      e.$sum(sum =>
                        sum.$cond(
                          _if =>
                            _if.$or(
                              e =>
                                e.$and(
                                  e =>
                                    e.$eq(
                                      e =>
                                        e.$fieldPath(f => f('status')('phase')),
                                      e => e.$literal('退款'),
                                    ),
                                  e =>
                                    e.$ne(
                                      e => e.$fieldPath(f => f('spec')('type')),
                                      e => e.$literal('商城'),
                                    ),
                                ),
                              e =>
                                e.$and(
                                  e =>
                                    e.$eq(
                                      e =>
                                        e.$fieldPath(f => f('status')('phase')),
                                      e => e.$literal('取消'),
                                    ),
                                  e =>
                                    e.$fieldPath(f => f('status')('payInfo')),
                                  e =>
                                    e.$ne(
                                      e => e.$fieldPath(f => f('spec')('type')),
                                      e => e.$literal('商城'),
                                    ),
                                ),
                            ),
                          _then =>
                            _then.$fieldPath(f => f('spec')('amount')('user')),
                          _else => _else.$literal(0),
                        ),
                      ),
                    )('refundCount', e =>
                      e.$sum(sum =>
                        sum.$cond(
                          _if =>
                            _if.$or(
                              e =>
                                e.$eq(
                                  e => e.$fieldPath(f => f('status')('phase')),
                                  e => e.$literal('退款'),
                                ),
                              e =>
                                e.$and(
                                  e =>
                                    e.$eq(
                                      e =>
                                        e.$fieldPath(f => f('status')('phase')),
                                      e => e.$literal('取消'),
                                    ),
                                  e =>
                                    e.$fieldPath(f => f('status')('payInfo')),
                                ),
                            ),
                          _then => _then.$literal(1),
                          _else => _else.$literal(0),
                        ),
                      ),
                    ),
                );
                if (this.groupType === 'time') {
                  tmallStage.$sort(sort => sort(f => f('_id')('date'), '降序'));
                } else {
                  if (this.sortVal === '订单数') {
                    tmallStage.$sort(sort =>
                      sort(f => f('count'), this.sortType as '升序' | '降序'),
                    );
                  }
                  if (this.sortVal === '交易金额(￥)') {
                    tmallStage.$sort(sort =>
                      sort(f => f('user'), this.sortType as '升序' | '降序'),
                    );
                  }
                }
                tmallStage.$skip((i - 1) * 100);
                tmallStage.$limit(100);
                return tmallStage;
              }),
            ),
        );
        let shopIdData: Array<ObjectId> = [];
        list[0].mallData.forEach(item => {
          shopIdData.push(item._id.shopId);
        });
        shopIdData = Array.from(new Set(shopIdData));
        const shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        const shopInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shopData.map(v => v._id.toHexString()) as any,
          shopData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
        list[0].mallData.forEach(item => {
          if (this.groupType === 'time') {
            reportList.push([
              item._id.date,
              shopInfoData[item._id.shopId.toHexString()] &&
              shopInfoData[item._id.shopId.toHexString()].spec
                ? shopInfoData[item._id.shopId.toHexString()].spec.name
                : '--',
              item.count,
              (item.user / 100).toFixed(2),
              item.refundCount,
              ((item.shopRefundAmount + item.otherRefundAmount) / 100).toFixed(
                2,
              ),
            ]);
          } else {
            reportList.push([
              shopInfoData[item._id.shopId.toHexString()] &&
              shopInfoData[item._id.shopId.toHexString()].spec
                ? shopInfoData[item._id.shopId.toHexString()].spec.name
                : '--',
              item.count,
              Number((item.user / 100).toFixed(2)),
              item.refundCount,
              Number(
                (
                  (item.shopRefundAmount + item.otherRefundAmount) /
                  100
                ).toFixed(2),
              ),
            ]);
          }
        });
      }
      const filename = moment().format('YYYY-MM-DD') + '商城订单汇总.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //汇总导出
  private async chartExportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      reportList.push([
        '日期',
        '订单数',
        '交易金额(￥)',
        '退款单数',
        '退款金额(￥)',
      ]);

      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                )(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                );
                if (this.role === '店铺' || this.role === '财务') {
                  match(
                    f => f('spec')('shopId'),
                    e => e.$in(this.shopAuthority),
                  );
                }
                if (this.orderType !== '全部') {
                  query(
                    f => f('spec')('type'),
                    e =>
                      e.$eq(
                        this.orderType as '商城' | '跑腿' | '提现' | '二手',
                      ),
                  );
                }
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.searchData.time[0]).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1])
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('chartsData', chartsStage =>
              chartsStage
                .$group(
                  e =>
                    e.$object(object =>
                      object('date', e =>
                        e.$dateToString(
                          date =>
                            date.$fieldPath(f =>
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              f('metadata')('creationTimestamp'),
                            ),
                          format => format.$literal('%Y-%m-%d'),
                          timezone => timezone.$literal('+08'),
                        ),
                      ),
                    ),
                  group =>
                    group('user', e =>
                      e.$sum(sum =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sum.$fieldPath(f => f('spec')('amount')('user')),
                      ),
                    )('count', e => e.$sum(sum => sum.$literal(1)))(
                      'shopRefundAmount',
                      e =>
                        e.$sum(sum =>
                          sum.$cond(
                            _if =>
                              _if.$eq(
                                e => e.$fieldPath(f => f('spec')('type')),
                                e => e.$literal('商城'),
                              ),
                            _then =>
                              _then.$fieldPath(f =>
                                f('spec')('refund')('user'),
                              ),
                            _else => _else.$literal(0),
                          ),
                        ),
                    )('otherRefundAmount', e =>
                      e.$sum(sum =>
                        sum.$cond(
                          _if =>
                            _if.$or(
                              e =>
                                e.$and(
                                  e =>
                                    e.$eq(
                                      e =>
                                        e.$fieldPath(f => f('status')('phase')),
                                      e => e.$literal('退款'),
                                    ),
                                  e =>
                                    e.$ne(
                                      e => e.$fieldPath(f => f('spec')('type')),
                                      e => e.$literal('商城'),
                                    ),
                                ),
                              e =>
                                e.$and(
                                  e =>
                                    e.$eq(
                                      e =>
                                        e.$fieldPath(f => f('status')('phase')),
                                      e => e.$literal('取消'),
                                    ),
                                  e =>
                                    e.$fieldPath(f => f('status')('payInfo')),
                                  e =>
                                    e.$ne(
                                      e => e.$fieldPath(f => f('spec')('type')),
                                      e => e.$literal('商城'),
                                    ),
                                ),
                            ),
                          _then =>
                            _then.$fieldPath(f => f('spec')('amount')('user')),
                          _else => _else.$literal(0),
                        ),
                      ),
                    )('refundCount', e =>
                      e.$sum(sum =>
                        sum.$cond(
                          _if =>
                            _if.$or(
                              e =>
                                e.$eq(
                                  e => e.$fieldPath(f => f('status')('phase')),
                                  e => e.$literal('退款'),
                                ),
                              e =>
                                e.$and(
                                  e =>
                                    e.$eq(
                                      e =>
                                        e.$fieldPath(f => f('status')('phase')),
                                      e => e.$literal('取消'),
                                    ),
                                  e =>
                                    e.$fieldPath(f => f('status')('payInfo')),
                                ),
                            ),
                          _then => _then.$literal(1),
                          _else => _else.$literal(0),
                        ),
                      ),
                    ),
                )
                .$sort(sort => sort(f => f('_id')('date'), '降序')),
            ),
          ),
      );
      list[0].chartsData.forEach(item => {
        reportList.push([
          item._id.date,
          item.count,
          Number((item.user / 100).toFixed(2)),
          item.refundCount,
          Number(
            ((item.shopRefundAmount + item.otherRefundAmount) / 100).toFixed(2),
          ),
        ]);
      });
      const filename = moment().format('YYYY-MM-DD') + '订单汇总.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
}
